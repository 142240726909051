export const perttiyBer = (ber: string): string => {
  return ber
    .split('')
    .map((digit, i) => {
      if (i === 2 || i === 5) {
        return `${digit}-`;
      }
      return digit;
    })
    .join('');
};
