import React, {forwardRef} from 'react';
import {Box} from 'theme-ui';

const CheckboxChecked = (props: any) => (
  <Box {...props}>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H14C15.933 0.5 17.5 2.067 17.5 4V14C17.5 15.933 15.933 17.5 14 17.5H4C2.067 17.5 0.5 15.933 0.5 14V4Z"
        fill="white"
        stroke="#B61F2F"
      />
      <path
        d="M13.1414 6.18066L7.72736 11.5945L5.02032 8.8877"
        stroke="#B61F2F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Box>
);

const CheckboxUnchecked = (props: any) => (
  <Box {...props}>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="white" stroke="#C8C8C8" />
    </svg>
  </Box>
);

const CheckboxIcon = (props: any) => (
  <>
    <CheckboxChecked
      {...props}
      sx={{
        display: 'none',
        'input:checked ~ &': {
          display: 'block',
        },
      }}
    />
    <CheckboxUnchecked
      {...props}
      sx={{
        display: 'block',
        'input:checked ~ &': {
          display: 'none',
        },
      }}
    />
  </>
);

type CheckboxProps = JSX.IntrinsicElements['input'];

export const Checkbox2 = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  return (
    <Box>
      <input
        ref={ref}
        type="checkbox"
        {...props}
        style={{
          position: 'absolute',
          opacity: 0,
          zIndex: -1,
          width: 1,
          height: 1,
          overflow: 'hidden',
        }}
      />
      <Box
        as={CheckboxIcon}
        aria-hidden="true"
        sx={{
          outline: 'none',
          mr: 1,
          borderRadius: 4,
          color: 'gray',
          flexShrink: 0,
          'input:checked ~ &': {
            color: 'primary',
          },
          'input:focus ~ &': {
            color: 'primary',
          },
        }}
      />
    </Box>
  );
});

Checkbox2.displayName = 'checkbox2';
