import {useLazyQuery, useMutation} from '@apollo/client';
import styled from '@emotion/styled';
import {Tab, TabList, TabPanel, TabPanels, Tabs} from '@reach/tabs';
import '@reach/tabs/styles.css';
import {getUnixTime} from 'date-fns';
import _ from 'lodash';
import {ParseResult} from 'papaparse';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {CSVReader} from 'react-papaparse';
import {useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {Column, usePagination, useSortBy, useTable} from 'react-table';
import {toast} from 'react-toastify';
import {AspectImage, Box, Button, Container, Flex, Input, Label, Radio, Spinner, Text} from 'theme-ui';
import {Modal} from '../../components/Modal/Modal';
import {ShopPhoto} from '../../components/Upload/ShopPhoto';
import {ChangePasswordResult, ChangePasswordVars, CHANGE_PASSWORD} from '../../graph/auth';
import {
  BatchUpsertBerResult,
  BatchUpsertBerVars,
  BATCH_UPSERT_BER,
  GetSellerResult,
  GetSellerVars,
  GET_SELLER,
} from '../../graph/ber';
import {UpdateShopResult, UpdateShopVars, UPDATE_SHOP} from '../../graph/shop';
import {BerProvider, UpsertBerInput} from '../../graph/types';
import {AppDispatch} from '../../state';
import {updateUserSession} from '../../state/user/actions';
import {useSessionManager} from '../../state/user/hooks';

export const validateNumber = (number: string) => /^([0-9]{10})$/.test(number);
export const validatePrice = (number: string) => /^[0-9]+$/.test(number);
export const validateProvier = (provider: string) => {
  const p: BerProvider | undefined = BerProvider[provider as keyof typeof BerProvider];
  return !!p;
};
export const mapProvider = (provider: string): string => {
  if (provider.toUpperCase().indexOf('AIS') > -1) {
    return 'AIS';
  } else if (provider.toUpperCase().indexOf('TRUE') > -1) {
    return 'TRUE';
  } else if (provider.toUpperCase().indexOf('DTAC') > -1) {
    return 'DTAC';
  } else if (provider.toUpperCase().indexOf('CAT') > -1) {
    return 'MYBYCAT';
  } else if (provider.toUpperCase().indexOf('MY') > -1) {
    return 'MYBYCAT';
  } else if (provider.toUpperCase().indexOf('TOT') > -1) {
    return 'TOT';
  } else if (provider.toUpperCase().indexOf('PENGUIN') > -1) {
    return 'PENGUIN';
  } else if (provider.toUpperCase().indexOf('PG') > -1) {
    return 'PENGUIN';
  } else if (provider.toUpperCase().indexOf('IEC') > -1) {
    return 'IEC';
  } else if (provider.toUpperCase().indexOf('IMOBILE') > -1) {
    return 'IMOBILE';
  }
  return provider;
};

const transform = (value: string, field: string) => {
  if (field.toLowerCase() === 'number') {
    let val = value.replace(/[^0-9+]/g, '');
    if (val.length === 9 && val[0] !== '0') {
      val = '0' + val;
    }

    if (validateNumber(val)) {
      return val;
    } else {
      return `❌ ${val}`;
    }
  } else if (field.toLowerCase() === 'price') {
    const val = value.replace(/,/g, '');
    if (validatePrice(val)) {
      return val;
    } else {
      return `❌ ${val}`;
    }
  } else if (field.toLowerCase() === 'provider') {
    const val = mapProvider(value.replace(/[^a-z+]/gi, ''));
    if (validateProvier(val)) {
      return val;
    } else {
      return `❌ ${val}`;
    }
  }

  return value;
};
const StyledTabList = styled(TabList)`
  width: 100%;
  justify-content: space-evenly;

  &[data-reach-tab-list] {
    background-color: #ffffff;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  padding-top: 16px;
`;

type RawBer = {
  number: string;
  price: string;
  provider: string;
};

export default function Seller() {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const {session, login} = useSessionManager();
  const buttonRef = useRef<CSVReader<RawBer>>(null);
  const [uploading, setUploading] = useState(false);
  const [doneUploading, setDoneUploading] = useState(false);
  const [parseResults, setParseResults] = useState<ParseResult<RawBer>[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [validCount, setValidCount] = useState(0);
  const [invalidCount, setInvalidCount] = useState(0);
  const [shopName, setShopName] = useState('');
  const [shopLineId, setShopLineId] = useState('');
  const [tel, setTel] = useState('');
  const [curPassword, setCurPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [deleteOther, setDeleteOther] = useState(false);

  useEffect(() => {
    if (!session) {
      login();
    }
  }, [session, login]);

  const [email, setEmail] = useState(session?.user?.email);

  const [fetchSeller, {data: sellerResult}] = useLazyQuery<GetSellerResult, GetSellerVars>(GET_SELLER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data) return;

      setShopName(data.shop.name);
      setShopLineId(data.shop.lineId);
      setTel(data.shop.tel);
    },
    onError: (error) => {
      toast(error.message, {type: 'error'});
      history.push('/login');
    },
  });
  const [fetchChangePassword, {loading: changePasswordLoading}] = useMutation<ChangePasswordResult, ChangePasswordVars>(
    CHANGE_PASSWORD,
    {
      onCompleted: (data) => {
        toast('เปลี่ยนรหัสผ่านสำเร็จ');
        dispatch(updateUserSession({session: data.session}));

        setCurPassword('');
        setNewPassword('');
      },
      onError: (error) => {
        toast(error.message, {type: 'error'});
      },
    }
  );

  const [fetchUpdateShop, {loading: updateShopLoading}] = useMutation<UpdateShopResult, UpdateShopVars>(UPDATE_SHOP, {
    onCompleted: () => {
      toast('แก้ไขข้อมูลร้านสำเร็จ');
    },
    onError: (error) => {
      toast(error.message, {type: 'error'});
    },
  });

  useEffect(() => {
    fetchSeller();
  }, [fetchSeller]);

  const [rows, columns] = useMemo<[RawBer[], Column<RawBer>[]]>(() => {
    if (parseResults.length === 0) return [[], []];

    return [
      parseResults.map((e) => {
        return e.data as unknown as RawBer;
      }),
      (parseResults[0].meta.fields || []).map((e) => {
        return {
          Header: e,
          accessor: e,
        } as Column<RawBer>;
      }),
    ];
  }, [parseResults]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {pageIndex},
  } = useTable({columns, data: rows}, useSortBy, usePagination);

  const [fetchBatchUpsertBer] = useMutation<BatchUpsertBerResult, BatchUpsertBerVars>(BATCH_UPSERT_BER, {
    onError: (error) => {
      toast(error.message, {type: 'error'});
    },
  });

  const uploadBatch = async () => {
    if (!rows) return;

    const chunkSize = 100;
    const batchId = getUnixTime(new Date());
    let totalUpdated = 0;
    let totalDeleted = 0;
    try {
      setUploading(true);
      for (const chunk of _.chunk(rows, chunkSize)) {
        const result = await fetchBatchUpsertBer({
          variables: {
            input: chunk.map(({number, price, provider}) => {
              return {
                number,
                price: parseInt(price),
                provider,
              } as UpsertBerInput;
            }),
            batchId,
            deleteOther,
          },
        });
        totalUpdated += result.data?.result.upserted || 0;
        totalDeleted += result.data?.result.deleted || 0;
      }

      const strs = [`อัพเดทเบอร์สำเร็จ เพิ่ม ${totalUpdated.toLocaleString()} เบอร์`];
      if (totalDeleted > 0) {
        strs.push(`ลบ ${totalDeleted.toLocaleString()} เบอร์`);
      }

      toast(strs.join(' | '));
    } catch (error) {
    } finally {
      setUploading(false);
      setDoneUploading(true);
    }
  };

  const handleUploadBer = () => {
    setShowDialog(true);
  };

  const handleOpenDialog = (e: any) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnError = (err: any) => {
    console.log(err);
  };

  const handleOnFileLoad = (result: ParseResult<RawBer>[]) => {
    setParseResults(result);
    setTotalCount(result.length);

    setValidCount(
      result.filter(({data}) => {
        const {number, price, provider} = data as unknown as RawBer;
        return number[0] !== '❌' && price[0] !== '❌' && provider[0] !== '❌';
      }).length
    );
    setInvalidCount(
      result.filter(({data}) => {
        const {number, price, provider} = data as unknown as RawBer;
        return number[0] === '❌' || price[0] === '❌' || provider[0] === '❌';
      }).length
    );

    // reset state
    setUploading(false);
    setDoneUploading(false);
    setDeleteOther(false);
  };

  const handleChangePassword = () => {
    if (changePasswordLoading) return;

    fetchChangePassword({
      variables: {
        curPassword,
        newPassword,
      },
    });
  };

  const handleUpdateShop = () => {
    if (updateShopLoading) return;

    fetchUpdateShop({
      variables: {
        name: shopName,
        lineId: shopLineId,
        tel: tel,
      },
    });
  };

  const handleLogout = () => {
    dispatch(updateUserSession({session: null}));
  };

  return (
    <Box>
      <Container sx={{px: '16px', py: '24px'}}>
        <Modal showDialog={showDialog} onDismiss={() => setShowDialog(false)}>
          <>
            <Text variant="header">เพิ่มเบอร์เข้าไปที่หน้าร้าน</Text>
            <Flex sx={{flexDirection: 'column', my: ['16px'], gap: '16px'}}>
              <Label variant="labelCaption">
                <Radio name="dark-mode" checked={deleteOther === false} onChange={() => setDeleteOther(false)} />
                เก็บเบอร์เดิมไว้ (เบอร์ที่ซ้ำจะถูกอัพเดท)
              </Label>
              <Label variant="labelCaption">
                <Radio name="dark-mode" checked={deleteOther} onChange={() => setDeleteOther(true)} />
                ลบเบอร์เดิมออกให้หมด
              </Label>
            </Flex>
            <Button
              variant="accent"
              onClick={() => {
                setShowDialog(false);
                uploadBatch();
              }}
            >
              ยืนยัน
            </Button>
          </>
        </Modal>
        <Tabs>
          <StyledTabList>
            <Tab>เพิ่มเบอร์</Tab>
            <Tab>ข้อมูลร้าน</Tab>
          </StyledTabList>

          <TabPanels>
            {/* Upload Ber */}
            <StyledTabPanel>
              <Box sx={{mt: '16px'}}>
                <CSVReader
                  ref={buttonRef}
                  onFileLoad={handleOnFileLoad}
                  onError={handleOnError}
                  noClick
                  noDrag
                  config={{skipEmptyLines: true, header: true, transform}}
                  progressBarColor={'#3665DD'}
                  style={{}}
                >
                  {({file}: {file: any}) => (
                    <Flex>
                      <Button variant="accentHallow" sx={{width: '100%'}} onClick={handleOpenDialog}>
                        อัพโหลดไฟล์ {(file && file.name) || '.csv'}
                      </Button>
                    </Flex>
                  )}
                </CSVReader>
              </Box>

              {totalCount == 0 && (
                <Flex sx={{flexDirection: 'column', gap: '8px', mt: '16px'}}>
                  <Text variant="title">สร้างไฟล์ csv ขึ้นมาใหม่ให้เหมือนภาพนี้:</Text>
                  <AspectImage ratio={10 / 5} src="/images/berhit-csv.png" />
                  <Text variant="title">ข้อสังเกต:</Text>
                  <Text>
                    มีแค่ 3 column (<Text sx={{fontWeight: 500}}> number price provider</Text>) เท่านั้น
                    กรุณาลบข้อมูลอื่นๆออก
                  </Text>
                  <Text>
                    1. column <Text sx={{fontWeight: 500}}>number</Text> ใส่เบอร์ 10 หลัก (ใส่หรือไม่ใส่ขีดกลางก็ได้)
                  </Text>
                  <Text>
                    2. column <Text sx={{fontWeight: 500}}>price</Text> ใส่ราคา (ไม่ต้องใส่ลูกน้ำ){' '}
                  </Text>
                  <Text>
                    3. column <Text sx={{fontWeight: 500}}>provider</Text> ใส่เครือข่าย ใช้ตัวสะกดตามนี้
                  </Text>
                  <Flex sx={{flexDirection: 'column', pl: '12px'}}>
                    <Text>AIS</Text>
                    <Text>TRUE</Text>
                    <Text>DTAC</Text>
                    <Text>TOT</Text>
                    <Text>MYBYCAT</Text>
                    <Text>IMOBILE</Text>
                    <Text>PENGUIN</Text>
                    <Text>IEC</Text>
                  </Flex>
                </Flex>
              )}

              <Flex sx={{flexDirection: 'column', mx: ['12px']}}>
                {rows.length > 0 && (
                  <Flex sx={{flexDirection: 'column', mt: ['16px']}}>
                    <Flex sx={{flexDirection: 'row'}}>
                      <Text variant="header" mr="24px">
                        ทั้งหมด {totalCount} เบอร์
                      </Text>
                      <Text variant="header" mr="24px">
                        ถูก {validCount}
                      </Text>
                      <Text variant="header" color="accent.r1000">
                        ผิด {invalidCount}
                      </Text>
                    </Flex>

                    <Box sx={{mt: '16px'}}>
                      <table
                        {...getTableProps()}
                        style={{border: '1px solid #C8C8C8', width: '100%', overflowX: 'scroll'}}
                      >
                        <thead>
                          {headerGroups.map((headerGroup, i) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                              {headerGroup.headers.map((column, j) => (
                                <th
                                  {...column.getHeaderProps(column.getSortByToggleProps())}
                                  style={{
                                    // borderBottom: 'solid 3px red',
                                    border: '1px solid #C8C8C8',
                                    fontWeight: 'bold',
                                  }}
                                  key={j}
                                >
                                  {column.render('Header')}
                                  <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {page.map((row, i) => {
                            prepareRow(row);
                            return (
                              <tr
                                {...row.getRowProps()}
                                key={i}
                                style={{
                                  fontWeight: 300,
                                  fontSize: '14px',
                                  lineHeight: '30px',
                                }}
                              >
                                {row.cells.map((cell, j) => {
                                  return (
                                    <td
                                      {...cell.getCellProps()}
                                      key={j}
                                      style={{
                                        border: '1px solid #C8C8C8',
                                      }}
                                    >
                                      {cell.render('Cell')}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <Box sx={{my: ['16px']}}>
                        <Button variant="primaryHallow" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                          {'<<'}
                        </Button>{' '}
                        <Button variant="primaryHallow" onClick={() => previousPage()} disabled={!canPreviousPage}>
                          {'<'}
                        </Button>{' '}
                        <Button variant="primaryHallow" onClick={() => nextPage()} disabled={!canNextPage}>
                          {'>'}
                        </Button>{' '}
                        <Button variant="primaryHallow" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                          {'>>'}
                        </Button>{' '}
                        <span>
                          Page{' '}
                          <strong>
                            {pageIndex + 1} of {pageOptions.length}
                          </strong>{' '}
                        </span>
                      </Box>
                    </Box>

                    <Button
                      variant="accentHallow"
                      onClick={handleUploadBer}
                      disabled={uploading || invalidCount > 0 || doneUploading}
                    >
                      {uploading ? <Spinner size={22} /> : <span>เพิ่มเบอร์</span>}
                    </Button>

                    {doneUploading && (
                      <Link to={`/shops/${sellerResult?.shop?.id}`}>
                        <Button variant="accentHallow" disabled={uploading} sx={{mt: '16px', width: '100%'}}>
                          ดูหน้าร้าน
                        </Button>
                      </Link>
                    )}
                  </Flex>
                )}
              </Flex>
            </StyledTabPanel>

            {/* Shop Profile */}
            <StyledTabPanel>
              <Flex sx={{flexDirection: 'column'}}>
                {sellerResult && <ShopPhoto shop={sellerResult.shop} />}

                <Label htmlFor="shopName" sx={{mt: '8px'}}>
                  ชื่อร้าน
                </Label>
                <Input
                  name="shopName"
                  id="shopName"
                  sx={{mt: '2px'}}
                  required
                  onChange={(e) => setShopName(e.target.value)}
                  value={shopName}
                  autoFocus
                />

                <Label htmlFor="shopLineId" sx={{mt: '8px'}}>
                  Line ไอดี
                </Label>
                <Input
                  name="shopLineId"
                  id="shopLineId"
                  sx={{mt: '2px'}}
                  required
                  onChange={(e) => setShopLineId(e.target.value)}
                  value={shopLineId}
                />

                <Label htmlFor="tel" sx={{mt: '8px'}}>
                  เบอร์โทร
                </Label>
                <Input
                  name="tel"
                  id="tel"
                  sx={{mt: '2px'}}
                  required
                  onChange={(e) => setTel(e.target.value)}
                  value={tel}
                />

                <Label htmlFor="email" sx={{mt: '8px'}}>
                  อีเมล
                </Label>
                <Input
                  name="email"
                  id="email"
                  sx={{mt: '2px'}}
                  disabled
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <Label htmlFor="password" sx={{mt: '8px'}}>
                  รหัสผ่านปัจจุบัน
                </Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  required
                  sx={{mt: '2px'}}
                  value={curPassword}
                  onChange={(e) => setCurPassword(e.target.value)}
                />

                <Flex sx={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline'}}>
                  <Label htmlFor="newPassword" sx={{mt: '8px', width: '100px'}}>
                    รหัสผ่านใหม่
                  </Label>
                  <Button variant="text" onClick={handleChangePassword}>
                    <Text variant="caption" color="accent.b1000">
                      เปลี่ยนรหัสผ่าน
                    </Text>
                  </Button>
                </Flex>
                <Input
                  type="password"
                  name="newPassword"
                  id="newPassword"
                  required
                  sx={{mt: '2px'}}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />

                <Button variant="accent" disabled={updateShopLoading} sx={{mt: '16px'}} onClick={handleUpdateShop}>
                  {updateShopLoading ? <Spinner size={22} /> : <span>อัพเดทข้อมูลร้าน</span>}
                </Button>

                <Link to={`/shops/${sellerResult?.shop?.id}`}>
                  <Button variant="accentHallow" disabled={uploading} sx={{mt: '16px', width: '100%'}}>
                    ดูหน้าร้าน
                  </Button>
                </Link>

                <Button variant="text" sx={{m: '16px auto'}} onClick={handleLogout}>
                  <Text variant="caption" color="accent.b1000">
                    ออกจากระบบ
                  </Text>
                </Button>
              </Flex>
            </StyledTabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </Box>
  );
}
