import styled from '@emotion/styled';
import {DialogContent, DialogOverlay} from '@reach/dialog';
import '@reach/dialog/styles.css';
import React, {FC} from 'react';
import {FiX} from 'react-icons/fi';
import {Tag} from 'react-tag-input';
import {Box, Button, Flex} from 'theme-ui';
import {TAGS} from '../../constant';

export const StyledDialogOverlay = styled(DialogOverlay)`
  /* background: hsla(1, 100%, 100%, 0.9); */
`;

export const StyledDialogContent = styled(DialogContent)`
  width: 90vw;
  max-width: 350px;
  min-height: 50vh;
  padding: 24px;
  border-radius: 6px;
`;

export type TagsModalProps = {
  isOpen?: boolean;
  onDismiss: () => void;
  selectedTags: Tag[];
  appendTag: (tag: Tag) => void;
  removeTag: (tag: Tag) => void;
};

export const TagsModal: FC<TagsModalProps> = ({isOpen, onDismiss, selectedTags, appendTag, removeTag}) => {
  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={onDismiss}>
      <StyledDialogContent aria-label={`all tags`}>
        <Box sx={{position: 'relative'}}>
          <Box onClick={onDismiss} sx={{position: 'absolute', right: '-10px', top: '-10px', cursor: 'pointer'}}>
            <FiX size="22px" />
          </Box>
          <Flex sx={{flexDirection: 'row', flexWrap: 'wrap'}}>
            {TAGS.map((tag) => {
              const selected = selectedTags.find((t) => t.id === tag.id);
              return (
                <Button
                  variant="tag"
                  key={tag.id}
                  sx={{mx: '6px', my: '6px', bg: selected ? 'neutral.n100' : 'neutral.n10'}}
                  onClick={() => (!selected ? appendTag(tag) : removeTag(tag))}
                >
                  <span>{tag.text}</span>
                  {selected ? <FiX /> : ' '}
                </Button>
              );
            })}
          </Flex>
        </Box>
      </StyledDialogContent>
    </StyledDialogOverlay>
  );
};
