import {useMutation} from '@apollo/client';
import React, {ChangeEvent} from 'react';
import {toast} from 'react-toastify';
import {Box, Flex, Image, Input, Label} from 'theme-ui';
import {STATIC_ASSET_PREFIX} from '../../constant';
import {UploadShopPhotoResult, UploadShopPhotoVars, UPLOAD_SHOP_PHOTO} from '../../graph/shop';
import {Shop} from '../../graph/types';

export const ShopPhoto = ({shop}: {shop: Shop}) => {
  const [upload, {data: updatedShop}] = useMutation<UploadShopPhotoResult, UploadShopPhotoVars>(UPLOAD_SHOP_PHOTO, {
    onCompleted: () => {
      toast('เปลี่ยนรูปสำเร็จ');
    },
    onError: (error) => {
      toast(error.message, {type: 'error'});
    },
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);
    if (!file) return;

    upload({
      variables: {
        file,
      },
    });
  };

  const pictureUrl = updatedShop ? updatedShop.shop.pictureUrl : shop.pictureUrl;
  const fullUrl = `${STATIC_ASSET_PREFIX}/${pictureUrl === '' ? 'sellers/shop-placeholder.png' : pictureUrl}`;

  return (
    <Flex sx={{flexDirection: 'column', alignItems: 'center', mt: '16px'}}>
      <Image variant="shop" src={fullUrl} />
      <Box as="form" sx={{mt: '8px'}}>
        <Label variant="uploadLabel" htmlFor="upload">
          เปลี่ยนรูป
        </Label>
        <Input
          name="upload"
          id="upload"
          type="file"
          accept="image/*"
          required
          onChange={onChange}
          sx={{
            width: '0.1px',
            height: '0.1px',
            opacity: 0,
            overflow: 'hidden',
            position: 'absolute',
            cursor: 'pointer',
            zIndex: -1,
          }}
        />
      </Box>
    </Flex>
  );
};
