import {gql} from '@apollo/client';
import {Shop, UserRole} from './types';

export type User = {
  email: string;
  role: UserRole;
};

export type Session = {
  user?: User;
  accessToken: string;
  expiresAt: string;
  tokenType: string;
  shop?: Shop;
};

export type LoginEmailResult = {
  session: Session;
};

export type LoginEmailVars = {
  email: string;
  password: string;
};

export const LOGIN_EMAIL = gql`
  mutation loginEmail($email: String!, $password: String!) {
    session: loginEmail(input: {email: $email, password: $password}) {
      accessToken
      expiresAt
      tokenType
      user {
        email
        role
      }
      shop {
        id
        name
        lineId
      }
    }
  }
`;

export type SignupEmailResult = {
  session: Session;
};

export type SignupEmailVars = {
  email: string;
  password: string;
  shopName: string;
  shopLineId: string;
};

export const SIGNUP_EMAIL = gql`
  mutation signupEmail($email: String!, $password: String!, $shopName: String!, $shopLineId: String!) {
    session: signupEmail(input: {email: $email, password: $password, shopName: $shopName, shopLineId: $shopLineId}) {
      accessToken
      expiresAt
      tokenType
      user {
        email
        role
      }
      shop {
        id
        name
        lineId
      }
    }
  }
`;

export type ForgetPasswordResult = {
  session: Session;
};

export type ForgetPasswordVars = {
  email: string;
};

export const FORGET_PASSWORD = gql`
  mutation forgetpassword($email: String!) {
    session: forgetPassword(input: {email: $email}) {
      accessToken
    }
  }
`;

export type ResetPasswordResult = {
  session: Session;
};

export type ResetPasswordVars = {
  password: string;
};

export const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!) {
    session: resetPassword(input: {password: $password}) {
      accessToken
      expiresAt
      tokenType
      user {
        email
        role
      }
      shop {
        id
        name
        lineId
      }
    }
  }
`;

export type ChangePasswordResult = {
  session: Session;
};

export type ChangePasswordVars = {
  curPassword: string;
  newPassword: string;
};

export const CHANGE_PASSWORD = gql`
  mutation changePassword($curPassword: String!, $newPassword: String!) {
    session: changePassword(input: {curPassword: $curPassword, newPassword: $newPassword}) {
      accessToken
      expiresAt
      tokenType
      user {
        email
        role
      }
      shop {
        id
        name
        lineId
      }
    }
  }
`;
