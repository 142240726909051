import {createReducer} from '@reduxjs/toolkit';
import {Session} from '../../graph/auth';
import {updateVersion} from '../global/actions';
import {updateMarchesDarkMode, updateUserDarkMode, updateUserSession} from './actions';

const currentTimestamp = () => new Date().getTime();

export interface UserState {
  // the timestamp of the last updateVersion action
  lastUpdateVersionTimestamp?: number;

  userDarkMode: boolean | null; // the user's choice for dark mode or light mode
  matchesDarkMode: boolean; // whether the dark mode media query matches

  userSession: Session | null;

  timestamp: number;
}

export const initialState: UserState = {
  userDarkMode: null,
  matchesDarkMode: false,
  userSession: null,
  timestamp: currentTimestamp(),
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateVersion, (state) => {
      state.lastUpdateVersionTimestamp = currentTimestamp();
    })
    .addCase(updateUserDarkMode, (state, action) => {
      state.userDarkMode = action.payload.userDarkMode;
      state.timestamp = currentTimestamp();
    })
    .addCase(updateMarchesDarkMode, (state, action) => {
      state.matchesDarkMode = action.payload.matchesDarkMode;
      state.timestamp = currentTimestamp();
    })
    .addCase(updateUserSession, (state, action) => {
      state.userSession = action.payload.session;
      state.timestamp = currentTimestamp();
    })
);
