import {ApolloProvider} from '@apollo/client';
import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {client} from '../src/connectors/graphql';
import store from '../src/state';
import '../src/styles/index.scss';
import {GlobalStyle, ThemeGlobalStyle, ThemeProvider} from '../src/theme';
import App from './pages/App';

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <GlobalStyle />
        <ThemeProvider>
          <ThemeGlobalStyle />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </ApolloProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);
