import React, {FC, KeyboardEvent, KeyboardEventHandler, useState} from 'react';
import {Tag, WithContext as ReactTags} from 'react-tag-input';
import {Box, Button, Divider, Flex, Input, Label, Switch, Text} from 'theme-ui';
import {IconInfo, IconReset} from '../../components/Icon/Icon';
import {BerWhereInput} from '../../graph/types';
import {Checkbox2} from '../Checkbox/Checkbox2';
import {TriangleTooltip} from '../Tooltip/Tooltip';

const regex = /^\d+$/;

const KeyCodes = {
  comma: 188,
  enter: 13,
  tab: 9,
  space: 32,
};

const delimiters = [KeyCodes.tab, KeyCodes.space, KeyCodes.comma, KeyCodes.enter];

declare let window: any;

const validate: KeyboardEventHandler<HTMLInputElement> = (evt: KeyboardEvent<HTMLInputElement>) => {
  const theEvent = evt || window.event;

  let key;
  // Handle paste
  if (theEvent.type === 'paste') {
    key = window.clipboardData.getData('text/plain');
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }

  if (!regex.test(key)) {
    // theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
};

const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = (evt: KeyboardEvent<HTMLInputElement>) => {
  if (evt.key === 'Backspace') {
    const prev = evt.currentTarget.previousSibling as HTMLInputElement;

    if (prev) {
      prev.focus();
    }
  }
};

const handleKeyUp2: KeyboardEventHandler<HTMLInputElement> = (evt: KeyboardEvent<HTMLInputElement>) => {
  if (evt.key === 'Backspace') {
    const prev = evt.currentTarget.previousSibling?.previousSibling as HTMLInputElement;
    if (prev) {
      prev.focus();
    }
  }
};

export interface NumberFilterProps {
  onFilterUpdate?: (variable: BerWhereInput) => void;
}

export const NumberFilter: FC<NumberFilterProps> = ({onFilterUpdate}) => {
  const [where, setWhere] = useState<BerWhereInput>({
    digit2: '',
    digit3: '',
    digit4: '',
    digit5: '',
    digit6: '',
    digit7: '',
    digit8: '',
    digit9: '',
    digit10: '',
    contains: [],
    notContains: [],
    sums: [],
  });
  const [containsTags, setContainsTags] = useState<Tag[]>([]);
  const [notContainsTags, setNotContainsTags] = useState<Tag[]>([]);
  const [sumTags, setSumTags] = useState<Tag[]>([]);

  const handleResetFilter = () => {
    setWhere({
      digit2: '',
      digit3: '',
      digit4: '',
      digit5: '',
      digit6: '',
      digit7: '',
      digit8: '',
      digit9: '',
      digit10: '',
      contains: [],
      notContains: [],
      sums: [],
    });
    setContainsTags([]);
    setNotContainsTags([]);
    setSumTags([]);
    if (onFilterUpdate) onFilterUpdate({});
  };

  return (
    <Box>
      <Flex sx={{flexDirection: 'row', justifyContent: 'space-between'}}>
        <Text variant="title">หาเบอร์ตามตัวเลข</Text>
        <Button variant="text" onClick={() => handleResetFilter()}>
          <IconReset />
          รีเซ็ท
        </Button>
      </Flex>

      <Flex sx={{flexDirection: 'column', mt: ['16px']}}>
        <Text variant="caption">ระบุตำแหน่ง</Text>
        <Flex sx={{flexDirection: 'row', flexFlow: 'wrap', alignItems: 'center'}}>
          <Input variant="digitBox" maxLength={1} value={0} disabled />
          <Input
            variant="digitBox"
            maxLength={1}
            inputMode="numeric"
            value={where.digit2}
            onChange={(e) => {
              const vars: BerWhereInput = {...where, digit2: e.target.value};
              setWhere(vars);
              if (onFilterUpdate) onFilterUpdate(vars);

              const next = e.target.nextSibling as HTMLInputElement;
              if (e.target.value && next) {
                next.focus();
                if (next.value && next.value !== '') {
                  next.select();
                }
              }
            }}
            onKeyPress={validate}
          />
          <Input
            variant="digitBox"
            maxLength={1}
            inputMode="numeric"
            value={where.digit3}
            onChange={(e) => {
              const vars: BerWhereInput = {...where, digit3: e.target.value};
              setWhere(vars);
              if (onFilterUpdate) onFilterUpdate(vars);

              const next = e.target.nextSibling?.nextSibling as HTMLInputElement;
              if (e.target.value && next) {
                next.focus();
                if (next.value && next.value !== '') {
                  next.select();
                }
              }
            }}
            onKeyPress={validate}
            onKeyUp={handleKeyUp}
          />
          <Text variant="digitSeparator">-</Text>
          <Input
            variant="digitBox"
            maxLength={1}
            inputMode="numeric"
            value={where.digit4}
            onChange={(e) => {
              const vars: BerWhereInput = {...where, digit4: e.target.value};
              setWhere(vars);
              if (onFilterUpdate) onFilterUpdate(vars);

              const next = e.target.nextSibling as HTMLInputElement;
              if (e.target.value && next) {
                next.focus();
                if (next.value && next.value !== '') {
                  next.select();
                }
              }
            }}
            onKeyPress={validate}
            onKeyUp={handleKeyUp2}
          />
          <Input
            variant="digitBox"
            maxLength={1}
            inputMode="numeric"
            value={where.digit5}
            onChange={(e) => {
              const vars: BerWhereInput = {...where, digit5: e.target.value};
              setWhere(vars);
              if (onFilterUpdate) onFilterUpdate(vars);

              const next = e.target.nextSibling as HTMLInputElement;
              if (e.target.value && next) {
                next.focus();
                if (next.value && next.value !== '') {
                  next.select();
                }
              }
            }}
            onKeyPress={validate}
            onKeyUp={handleKeyUp}
          />
          <Input
            variant="digitBox"
            maxLength={1}
            inputMode="numeric"
            value={where.digit6}
            onChange={(e) => {
              const vars: BerWhereInput = {...where, digit6: e.target.value};
              setWhere(vars);
              if (onFilterUpdate) onFilterUpdate(vars);

              const next = e.target.nextSibling?.nextSibling as HTMLInputElement;
              if (e.target.value && next) {
                next.focus();
                if (next.value && next.value !== '') {
                  next.select();
                }
              }
            }}
            onKeyPress={validate}
            onKeyUp={handleKeyUp}
          />
          <Text variant="digitSeparator">-</Text>
          <Input
            variant="digitBox"
            maxLength={1}
            inputMode="numeric"
            value={where.digit7}
            onChange={(e) => {
              const vars: BerWhereInput = {...where, digit7: e.target.value};
              setWhere(vars);
              if (onFilterUpdate) onFilterUpdate(vars);

              const next = e.target.nextSibling as HTMLInputElement;
              if (e.target.value && next) {
                next.focus();
                if (next.value && next.value !== '') {
                  next.select();
                }
              }
            }}
            onKeyPress={validate}
            onKeyUp={handleKeyUp2}
          />
          <Input
            variant="digitBox"
            maxLength={1}
            inputMode="numeric"
            value={where.digit8}
            onChange={(e) => {
              const vars: BerWhereInput = {...where, digit8: e.target.value};
              setWhere(vars);
              if (onFilterUpdate) onFilterUpdate(vars);

              const next = e.target.nextSibling as HTMLInputElement;
              if (e.target.value && next) {
                next.focus();
                if (next.value && next.value !== '') {
                  next.select();
                }
              }
            }}
            onKeyPress={validate}
            onKeyUp={handleKeyUp}
          />
          <Input
            variant="digitBox"
            maxLength={1}
            inputMode="numeric"
            value={where.digit9}
            onChange={(e) => {
              const vars: BerWhereInput = {...where, digit9: e.target.value};
              setWhere(vars);
              if (onFilterUpdate) onFilterUpdate(vars);

              const next = e.target.nextSibling as HTMLInputElement;
              if (e.target.value && next) {
                next.focus();
                if (next.value && next.value !== '') {
                  next.select();
                }
              }
            }}
            onKeyPress={validate}
            onKeyUp={handleKeyUp}
          />
          <Input
            variant="digitBox"
            maxLength={1}
            inputMode="numeric"
            value={where.digit10}
            onChange={(e) => {
              const vars: BerWhereInput = {...where, digit10: e.target.value};
              setWhere(vars);
              if (onFilterUpdate) onFilterUpdate(vars);
            }}
            onKeyPress={validate}
            onKeyUp={handleKeyUp}
          />
        </Flex>
      </Flex>

      <Flex sx={{flexDirection: 'row', justifyContent: 'space-between', mt: ['16px']}}>
        <Box sx={{flex: 1}}>
          <Flex sx={{alignItems: 'center'}}>
            <Text variant="caption" sx={{mr: ['4px']}}>
              เลขต้องมี
            </Text>
            <TriangleTooltip
              label="พิพม์เลขเดี่ยว คู่เลข หรือกลุ่มเลข และกด ENTER บนคีย์บอร์ดเพื่อเว้นวรรคระหว่างเลข"
              aria-label="เลขต้องมี"
            >
              <Box sx={{height: '19px', cursor: 'pointer'}}>
                <IconInfo />
              </Box>
            </TriangleTooltip>
          </Flex>
          <Box sx={{overflowX: 'hidden'}}>
            <ReactTags
              tags={containsTags}
              handleDelete={(i: number) => {
                const tags = containsTags.filter((tag, index) => index !== i);
                setContainsTags(tags);

                const vars: BerWhereInput = {
                  ...where,
                  contains: tags.map((tag) => tag.text),
                };
                setWhere(vars);
                if (onFilterUpdate) onFilterUpdate(vars);
              }}
              handleTagClick={(i: number) => {
                const tags = containsTags.filter((tag, index) => index !== i);
                setContainsTags(tags);

                const vars: BerWhereInput = {
                  ...where,
                  contains: tags.map((tag) => tag.text),
                };
                setWhere(vars);
                if (onFilterUpdate) onFilterUpdate(vars);
              }}
              handleAddition={(tag: Tag) => {
                if (!regex.test(tag.text)) return;

                const tags = [...containsTags, tag];
                setContainsTags(tags);

                const vars: BerWhereInput = {
                  ...where,
                  contains: tags.map((tag) => tag.text),
                };
                setWhere(vars);
                if (onFilterUpdate) onFilterUpdate(vars);
              }}
              inputFieldPosition="inline"
              inline={true}
              delimiters={delimiters}
              placeholder=""
              autofocus={false}
              handleInputBlur={(val: string) => {
                console.log(val);
              }}
            />
          </Box>
        </Box>
        <Flex sx={{width: ['70px'], flexDirection: 'column'}}>
          <Flex sx={{alignItems: 'center'}}>
            <Text variant="caption" sx={{mr: ['4px']}}>
              สลับคู่เลข
            </Text>
            <TriangleTooltip
              label="หากกำหนดให้มีเลข 56 แต่ต้องการดูเบอร์ที่มี 65 ด้วย ให้เปิดฟังชั่นสลับคู่เลข (กดให้เป็นสีแดง)"
              aria-label="สลับคู่เลข"
            >
              <Box sx={{height: '19px', cursor: 'pointer'}}>
                <IconInfo />
              </Box>
            </TriangleTooltip>
          </Flex>
          <Switch
            sx={{mt: ['4px'], justifyContent: 'flex-end'}}
            checked={where.swapPair}
            onChange={(e) => {
              const vars: BerWhereInput = {
                ...where,
                swapPair: e.target.checked,
              };
              setWhere(vars);
              if (onFilterUpdate) onFilterUpdate(vars);
            }}
          />
        </Flex>
      </Flex>
      <Divider />

      <Flex sx={{flexDirection: 'row', justifyContent: 'space-between', mt: ['16px']}}>
        <Box sx={{width: ['50%']}}>
          <Flex sx={{alignItems: 'center'}}>
            <Text variant="caption" sx={{mr: ['4px']}}>
              เลขที่ห้ามมี
            </Text>
            <TriangleTooltip
              label="พิพม์เลขเดี่ยว คู่เลข หรือกลุ่มเลข และกด ENTER บนคีย์บอร์ดเพื่อเว้นวรรคระหว่างเลข"
              aria-label="เลขที่ห้ามมี"
            >
              <Box sx={{height: '19px', cursor: 'pointer'}}>
                <IconInfo />
              </Box>
            </TriangleTooltip>
          </Flex>
          <Box sx={{overflowX: 'hidden'}}>
            <ReactTags
              tags={notContainsTags}
              handleDelete={(i: number) => {
                const tags = notContainsTags.filter((tag, index) => index !== i);
                setNotContainsTags(tags);

                const vars: BerWhereInput = {
                  ...where,
                  notContains: tags.map((tag) => tag.text),
                };
                setWhere(vars);
                if (onFilterUpdate) onFilterUpdate(vars);
              }}
              handleTagClick={(i: number) => {
                const tags = containsTags.filter((tag, index) => index !== i);
                setNotContainsTags(tags);

                const vars: BerWhereInput = {
                  ...where,
                  notContains: tags.map((tag) => tag.text),
                };
                setWhere(vars);
                if (onFilterUpdate) onFilterUpdate(vars);
              }}
              handleAddition={(tag: Tag) => {
                if (!regex.test(tag.text)) return;

                const tags = [...notContainsTags, tag];
                setNotContainsTags(tags);

                const vars: BerWhereInput = {
                  ...where,
                  notContains: tags.map((tag) => tag.text),
                };
                setWhere(vars);
                if (onFilterUpdate) onFilterUpdate(vars);
              }}
              inputFieldPosition="inline"
              inline={true}
              delimiters={delimiters}
              placeholder=""
              autofocus={false}
              handleInputBlur={(val: string) => {
                console.log(val);
              }}
            />
          </Box>
          <Divider sx={{mr: ['10%']}} />
        </Box>
        <Box sx={{width: ['50%']}}>
          <Flex sx={{alignItems: 'center'}}>
            <Text variant="caption" sx={{mr: ['4px']}}>
              ผลรวม
            </Text>
            <TriangleTooltip label="ใส่ผลรวมที่ต้องการ (สามารถกำหนดได้ตั้งแต่ 9 ถึง 81)" aria-label="เลขที่ห้ามมี">
              <Box sx={{height: '19px', cursor: 'pointer'}}>
                <IconInfo />
              </Box>
            </TriangleTooltip>
          </Flex>
          <Box sx={{overflowX: 'hidden'}}>
            <ReactTags
              tags={sumTags}
              handleDelete={(i: number) => {
                const tags = sumTags.filter((tag, index) => index !== i);
                setSumTags(tags);

                const vars: BerWhereInput = {
                  ...where,
                  sums: tags.map((tag) => parseInt(tag.text)),
                };
                setWhere(vars);
                if (onFilterUpdate) onFilterUpdate(vars);
              }}
              handleTagClick={(i: number) => {
                const tags = containsTags.filter((tag, index) => index !== i);
                setSumTags(tags);

                const vars: BerWhereInput = {
                  ...where,
                  sums: tags.map((tag) => parseInt(tag.text)),
                };
                setWhere(vars);
                if (onFilterUpdate) onFilterUpdate(vars);
              }}
              handleAddition={(tag: Tag) => {
                if (!regex.test(tag.text)) return;

                const tags = [...sumTags, tag];
                setSumTags(tags);

                const vars: BerWhereInput = {
                  ...where,
                  sums: tags.map((tag) => parseInt(tag.text)),
                };
                setWhere(vars);
                if (onFilterUpdate) onFilterUpdate(vars);
              }}
              inputFieldPosition="inline"
              inline={true}
              delimiters={delimiters}
              placeholder=""
              autofocus={false}
              handleInputBlur={(val: string) => {
                console.log(val);
              }}
            />
          </Box>
          <Divider />
        </Box>
      </Flex>

      <Flex sx={{flexDirection: 'row', justifyContent: 'space-between', mt: ['16px']}}>
        <Box>
          <Text variant="caption">ประเภท</Text>
          <Flex sx={{flexDirection: 'row', flexWrap: 'wrap', mt: ['8px']}}>
            <Box>
              <Label sx={{mb: ['4px'], mr: ['12px']}}>
                <Checkbox2
                  checked={where.isTriple}
                  onChange={(e) => {
                    const vars: BerWhereInput = {...where, isTriple: e.target.checked};
                    setWhere(vars);
                    if (onFilterUpdate) onFilterUpdate(vars);
                  }}
                />
                ตอง
              </Label>
            </Box>
            <Box>
              <Label sx={{mb: ['4px'], mr: ['12px']}}>
                <Checkbox2
                  checked={where.isQuadruple}
                  onChange={(e) => {
                    const vars: BerWhereInput = {...where, isQuadruple: e.target.checked};
                    setWhere(vars);
                    if (onFilterUpdate) onFilterUpdate(vars);
                  }}
                />
                โฟว์
              </Label>
            </Box>
            <Box>
              <Label sx={{mb: ['4px'], mr: ['12px']}}>
                <Checkbox2
                  checked={where.isQuintuple}
                  onChange={(e) => {
                    const vars: BerWhereInput = {...where, isQuintuple: e.target.checked};
                    setWhere(vars);
                    if (onFilterUpdate) onFilterUpdate(vars);
                  }}
                />
                ไฟว์
              </Label>
            </Box>
            <Box>
              <Label sx={{mb: ['4px'], mr: ['12px']}}>
                <Checkbox2
                  checked={where.isDoubleGroup}
                  onChange={(e) => {
                    const vars: BerWhereInput = {...where, isDoubleGroup: e.target.checked};
                    setWhere(vars);
                    if (onFilterUpdate) onFilterUpdate(vars);
                  }}
                />
                เบิ้ล
              </Label>
            </Box>
            <Box>
              <Label sx={{mb: ['4px'], mr: ['12px']}}>
                <Checkbox2
                  checked={where.isDragon}
                  onChange={(e) => {
                    const vars: BerWhereInput = {...where, isDragon: e.target.checked};
                    setWhere(vars);
                    if (onFilterUpdate) onFilterUpdate(vars);
                  }}
                />
                มังกร
              </Label>
            </Box>
            <Box>
              <Label sx={{mb: ['4px'], mr: ['12px']}}>
                <Checkbox2
                  checked={where.isPalindrome}
                  onChange={(e) => {
                    const vars: BerWhereInput = {...where, isPalindrome: e.target.checked};
                    setWhere(vars);
                    if (onFilterUpdate) onFilterUpdate(vars);
                  }}
                />
                กระจก
              </Label>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
