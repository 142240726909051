import {useMutation} from '@apollo/client';
import {addDays, getUnixTime} from 'date-fns';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {Box, Container, Flex, Input, Label, Text} from 'theme-ui';
import {ResetPasswordResult, ResetPasswordVars, RESET_PASSWORD} from '../../graph/auth';
import {AppDispatch} from '../../state';
import {updateUserSession} from '../../state/user/actions';

export default function ResetPassword() {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const {accessToken} = useParams<{accessToken: string}>();
  const [password, setPassword] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');
  const [fetchData] = useMutation<ResetPasswordResult, ResetPasswordVars>(RESET_PASSWORD, {
    variables: {
      password,
    },
    context: {},
    onCompleted: (data) => {
      if (!data) return;

      dispatch(updateUserSession({session: data.session}));

      history.push('/seller');
    },
  });

  useEffect(() => {
    console.log(accessToken);

    dispatch(
      updateUserSession({
        session: {
          accessToken,
          expiresAt: getUnixTime(addDays(new Date(), 1)).toString(),
          tokenType: 'Bearer',
        },
      })
    );
  }, [accessToken, dispatch]);

  const submit = (e: any) => {
    e.preventDefault();

    fetchData();
  };

  return (
    <Box sx={{bg: 'neutral.n10', height: '100vh'}}>
      <Container>
        <Flex sx={{justifyContent: 'center', alignItem: 'center'}}>
          <Box
            as="form"
            onSubmit={submit}
            sx={{width: ['303px'], bg: 'neutral.n0', my: '36px', p: '16px', borderRadius: '8px'}}
          >
            <Text variant="title">รีเซ็ทรหัสผ่าน</Text>

            <Label htmlFor="password" sx={{mt: '16px'}}>
              ตั้งรหัสผ่านใหม่
            </Label>
            <Input
              type="password"
              name="password"
              id="password"
              sx={{mt: '8px'}}
              onChange={(e) => setPassword(e.target.value)}
              autoFocus
            />

            <Label htmlFor="password2" sx={{mt: '16px'}}>
              พิมพอีกครั้ง
            </Label>
            <Input
              type="password"
              name="password2"
              id="password2"
              sx={{mt: '8px'}}
              onChange={(e) => setPassword2(e.target.value)}
            />

            <Input
              variant="submit"
              type="submit"
              value="รีเซ็ทรหัสผ่าน"
              sx={{mt: '16px', bg: 'accent.b1000'}}
              disabled={password !== password2}
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}
