import React, {FC, useState} from 'react';
import {Tag, WithContext as ReactTags} from 'react-tag-input';
import {Box, Button, Divider, Flex, Text} from 'theme-ui';
import {IconReset} from '../../components/Icon/Icon';
import {TAGS} from '../../constant';
import {BerWhereInput} from '../../graph/types';
import {TagsModal} from './TagsModal';

const KeyCodes = {
  comma: 188,
  enter: 13,
  tab: 9,
  space: 32,
};

const delimiters = [KeyCodes.tab, KeyCodes.space, KeyCodes.comma, KeyCodes.enter];

export interface TagFilterProps {
  onFilterUpdate?: (variable: BerWhereInput) => void;
}

export const TagFilter: FC<TagFilterProps> = ({onFilterUpdate}) => {
  const [where, setWhere] = useState<BerWhereInput>({});
  const [meaningTags, setMeaningTags] = useState<Tag[]>([]);
  const [showDialog, setShowDialog] = useState(false);

  const handleResetFilter = () => {
    setWhere({});
    setMeaningTags([]);
    if (onFilterUpdate) onFilterUpdate({});
  };

  return (
    <Box>
      <Flex sx={{flexDirection: 'row', justifyContent: 'space-between'}}>
        <Text variant="title">หาเบอร์ตามความหมาย </Text>
        <Button variant="text" onClick={() => handleResetFilter()}>
          <IconReset />
          รีเซ็ท
        </Button>
      </Flex>

      <Flex sx={{flexDirection: 'column', mt: ['16px']}}>
        <Flex sx={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <Text variant="caption" sx={{mr: ['4px']}}>
            พิมพ์สิ่งที่ต้องการเสริมในชีวิต
          </Text>
          <Button variant="text">
            <Text variant="caption" onClick={() => setShowDialog(!showDialog)}>
              ดูทั้งหมด
            </Text>
          </Button>
        </Flex>
        <Box>
          <ReactTags
            tags={meaningTags}
            handleDelete={(i: number) => {
              const tags = meaningTags.filter((tag, index) => index !== i);
              setMeaningTags(tags);

              const vars: BerWhereInput = {
                ...where,
                tags: tags.map((tag) => tag.id),
              };
              setWhere(vars);
              if (onFilterUpdate) onFilterUpdate(vars);
            }}
            handleTagClick={(i: number) => {
              const tags = meaningTags.filter((tag, index) => index !== i);
              setMeaningTags(tags);

              const vars: BerWhereInput = {
                ...where,
                tags: tags.map((tag) => tag.id),
              };
              setWhere(vars);
              if (onFilterUpdate) onFilterUpdate(vars);
            }}
            handleAddition={(tag: Tag) => {
              if (!TAGS.find((e) => e.text === tag.text)) return;

              const tags = [...meaningTags, tag];
              setMeaningTags(tags);

              const vars: BerWhereInput = {
                ...where,
                tags: tags.map((tag) => tag.id),
              };
              setWhere(vars);
              if (onFilterUpdate) onFilterUpdate(vars);
            }}
            suggestions={TAGS}
            minQueryLength={2}
            inputFieldPosition="inline"
            inline={true}
            delimiters={delimiters}
            placeholder=""
            autofocus={false}
          />
        </Box>
      </Flex>

      <TagsModal
        isOpen={showDialog}
        onDismiss={() => setShowDialog(false)}
        selectedTags={meaningTags}
        appendTag={(tag: Tag) => {
          const tags = [...meaningTags, tag];
          setMeaningTags(tags);

          const vars: BerWhereInput = {
            ...where,
            tags: tags.map((tag) => tag.id),
          };
          setWhere(vars);
          if (onFilterUpdate) onFilterUpdate(vars);
        }}
        removeTag={(tag: Tag) => {
          const tags = meaningTags.filter((t) => t.id !== tag.id);
          setMeaningTags(tags);

          const vars: BerWhereInput = {
            ...where,
            tags: tags.map((tag) => tag.id),
          };
          setWhere(vars);
          if (onFilterUpdate) onFilterUpdate(vars);
        }}
      />

      <Divider />
    </Box>
  );
};
