import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Box, Button, Container, Flex, Image, Input, Text} from 'theme-ui';
import {NAVBAR_HEIGH} from '../constant';
import {perttiyBer} from '../utils/prettify-ber';
import {LocationState} from './App';

export default function Evaluate() {
  const history = useHistory<LocationState>();
  const location = useLocation<LocationState>();
  const [number, setNumber] = useState('');

  const appendNumber = (n: string) => {
    if (number.length >= 10) return;
    setNumber(number + n);
  };

  const popNumber = () => {
    setNumber(number.slice(0, number.length - 1));
  };

  const submit = () => {
    if (number.length != 10 || number.slice(0, 1) !== '0') {
      toast('เบอร์มือถือไม่ถูกต้อง', {type: 'error'});
      return;
    }

    history.push(`/evaluate/${number}`, {background: location});
  };

  return (
    <Container sx={{backgroundImage: 'url(/images/grain.png)', height: `calc(100vh - ${NAVBAR_HEIGH})`}}>
      <Flex sx={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <Text variant="title">กดเบอร์มือถือ 10 หลัก</Text>
        <Box className="calculator" sx={{mt: ['20px']}}>
          <Box className="cal_mic" sx={{my: '12px'}}></Box>
          <Box className="form_remove_margin_bottom w-form">
            <Input
              className="phone_no_field"
              disabled
              value={perttiyBer(number)}
              sx={{
                mb: '16px',
                px: '12px',
                py: '8px',
                fontSize: '20px',
                letterSpacing: '1px',
                color: 'text.t1000',
                textDecoration: 'none',
                border: 'none',
              }}
            />
            <Box className="dial_pad_wrapper">
              <Box className="dial_button" mb="16px" onClick={() => appendNumber('1')}>
                1
              </Box>
              <Box className="dial_button" mb="16px" onClick={() => appendNumber('2')}>
                2
              </Box>
              <Box className="dial_button" mb="16px" onClick={() => appendNumber('3')}>
                3
              </Box>
              <Box className="dial_button" mb="16px" onClick={() => appendNumber('4')}>
                4
              </Box>
              <Box className="dial_button" mb="16px" onClick={() => appendNumber('5')}>
                5
              </Box>
              <Box className="dial_button" mb="16px" onClick={() => appendNumber('6')}>
                6
              </Box>
              <Box className="dial_button" mb="16px" onClick={() => appendNumber('7')}>
                7
              </Box>
              <Box className="dial_button" mb="16px" onClick={() => appendNumber('8')}>
                8
              </Box>
              <Box className="dial_button" mb="16px" onClick={() => appendNumber('9')}>
                9
              </Box>
              <Box className="dial_button" mb="16px" onClick={() => setNumber('')}>
                <Image src="images/icon_logo.png" loading="lazy" width="26" alt="" />
              </Box>
              <Box className="dial_button" mb="16px" onClick={() => appendNumber('0')}>
                0
              </Box>
              <Box className="dial_button" mb="16px" onClick={() => popNumber()}>
                <Image src="images/icon_del.png" loading="lazy" width="20" alt="" />
              </Box>
              <Button variant="primary" sx={{width: '100%'}} onClick={submit}>
                ทำนายเบอร์
              </Button>
            </Box>
          </Box>
        </Box>
        <Text variant="disclaimer" sx={{maxWidth: '350px', mt: ['40px'], textAlign: 'center'}}>
          การทำนายของเบอร์ฮิตจะไม่ขอข้อมูลอื่นนอกจากเลขมือถือ เบอร์ฮิตจะไม่เก็บและจะไม่ขายข้อมูลเบอร์มือถือเป็นอันขาด
          เบอร์ฮิตไม่สามารถเชื่อมโยงเลข 10 หลัก เข้ากับเจ้าของเบอร์ได้ เนื่องจากผู้ใช้คนนึงสามารถใส่ได้หลายเบอร์
          และจะใส่เบอร์ของตัวเองหรือเบอร์ของคนอื่นก็ได้
        </Text>
      </Flex>
    </Container>
  );
}
