import '@reach/dialog/styles.css';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {Box, Card, Divider, Flex, Text} from 'theme-ui';
import {BerGrade, EvaluationResult} from '../../graph/types';
import {perttiyBer} from '../../utils/prettify-ber';

const mapGradeMeaning = (grade: BerGrade): string => {
  switch (grade) {
    case BerGrade.AA:
      return 'เบอร์ดีมาก ความหมายมงคล';
    case BerGrade.A:
      return 'เบอร์ดี มีจุดอ่อนเพียงเล็กน้อย';
    case BerGrade.B:
      return 'เบอร์ธรรมดา มีจุดแข็งจุดอ่อน';
    case BerGrade.C:
      return 'เบอร์ธรรมดา มีจุดแข็งจุดอ่อน';
    case BerGrade.D:
      return 'เบอร์ไม่ดี เบอร์บอด จุดอ่อนเยอะ';
    case BerGrade.F:
      return 'เบอร์ต้องห้าม จุดอ่อนและข้อเสียเยอะมาก';
    default:
      return '';
  }
};

const mapGradeSuggestion = (grade: BerGrade): string => {
  switch (grade) {
    case BerGrade.AA:
      return 'ไม่ต้องเปลี่ยนเบอร์ หากชอบความหมายแล้ว';
    case BerGrade.A:
      return 'เปลี่ยนเบอร์ถ้าต้องการความหมายที่ดีขึ้น';
    case BerGrade.B:
      return 'แนะนำให้เปลี่ยนเบอร์ เพื่อเพิ่มความมงคล';
    case BerGrade.C:
      return 'แนะนำให้เปลี่ยนเบอร์ เพื่อเพิ่มความมงคล';
    case BerGrade.D:
      return 'ควรเลิกใช้เบอร์นี้ และเปลี่ยนเบอร์';
    case BerGrade.F:
      return 'ควรเลิกใช้เบอร์นี้ทันที และเปลี่ยนเบอร์';
    default:
      return '';
  }
};

const mapGrade = (ber: BerGrade) => (ber === BerGrade.AA ? 'A+' : ber);

export const BerEvaluation = ({result}: {result?: EvaluationResult}) => {
  const hasPros = result?.evaluations?.map((e) => e.pros).join('') !== '';
  const hasCons = result?.evaluations?.map((e) => e.cons).join('') !== '';

  return (
    <Box>
      <Flex sx={{flexDirection: 'row'}}>
        <Flex sx={{flexDirection: 'column', flexGrow: 1, mr: '12px'}}>
          <Text variant="title">{(result?.number && perttiyBer(result.number)) || <Skeleton />}</Text>
          <Flex sx={{flexDirection: 'column'}}>
            <Text variant="body">{(result?.grade && mapGradeMeaning(result.grade)) || <Skeleton />}</Text>
            <Text variant="body" color="text.t100">
              {(result?.grade && mapGradeSuggestion(result.grade)) || <Skeleton />}
            </Text>
          </Flex>
        </Flex>
        <Card variant="grade">
          <Flex sx={{flexDirection: 'column', alignItems: 'center', height: '100%'}}>
            <Text sx={{fontSize: '11px', lineHeight: '8px', color: 'accent.r1000', mt: '11px'}}>เกรด</Text>
            <Text sx={{fontSize: '30px', lineHeight: '28px', color: 'accent.r1000', mt: '6px', fontWeight: 500}}>
              {(result?.grade && mapGrade(result.grade)) || <Skeleton width="30px" />}
            </Text>
          </Flex>
        </Card>
      </Flex>

      <Divider sx={{mt: ['12px']}} />

      {hasPros && (
        <Flex sx={{flexDirection: 'column', mt: ['12px']}}>
          <Text variant="header">จุดเด่นของเบอร์นี้</Text>
          <Text variant="body" color="text.t1000" sx={{mt: ['12px']}}>
            {result?.evaluations?.map((e) => e.pros).join(' ') || <Skeleton count={2} />}
          </Text>
        </Flex>
      )}

      {hasCons && (
        <Flex sx={{flexDirection: 'column', mt: ['12px']}}>
          <Text variant="header">ข้อควรระวัง</Text>
          <Text variant="body" color="text.t1000" sx={{mt: ['12px']}}>
            {result?.evaluations?.map((e) => e.cons).join(' ') || <Skeleton count={2} />}
          </Text>
        </Flex>
      )}

      <Divider sx={{mt: ['12px']}} />

      <Flex sx={{flexDirection: 'column', mt: ['12px']}}>
        <Text variant="header">คำทำนายแบบละเอียด</Text>
        {result?.evaluations?.map(({description, weight, pair}, i) => (
          <Text key={i} variant="body" color="text.t1000" sx={{mt: ['12px']}}>
            {description}{' '}
            <Text color="text.t100">
              [{pair}/{pair.split('').reverse().join('')}] {weight}%
            </Text>
          </Text>
        )) || <Skeleton count={4} />}
      </Flex>
    </Box>
  );
};
