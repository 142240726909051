import '@reach/dialog/styles.css';
import {Location} from 'history';
import React from 'react';
import {Link} from 'react-router-dom';
import {Grid, Text} from 'theme-ui';
import {Ber} from '../../graph/types';
import {LocationState} from '../../pages/App';
import {perttiyBer} from '../../utils/prettify-ber';

export const columns = ['3fr 1fr 2fr 2fr'];

export const BerRow = ({ber, location}: {ber: Ber; location: Location<LocationState>}) => {
  return (
    <Grid gap={0} columns={columns} sx={{py: ['8px'], mt: ['12px'], cursor: 'pointer'}}>
      <Link to={{pathname: `/bers/${ber.id}`, state: {background: location}}}>
        <Text variant="digit">{perttiyBer(ber.number)}</Text>
      </Link>
      <Text variant="body" sx={{textAlign: 'right'}}>
        {ber.sum}
      </Text>
      <Text variant="body" sx={{textAlign: 'right'}}>
        {ber.price.toLocaleString()}
      </Text>
      <Text variant="body" sx={{textAlign: 'right'}}>
        {ber.provider}
      </Text>
    </Grid>
  );
};
