import {fromUnixTime, isBefore} from 'date-fns';
import {useCallback} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {Session} from '../../graph/auth';
import {AppDispatch, AppState} from '../index';
import {updateUserDarkMode} from './actions';

export const useIsDarkMode = (): boolean => {
  const {userDarkMode, matchesDarkMode} = useSelector<
    AppState,
    {userDarkMode: boolean | null; matchesDarkMode: boolean}
  >(
    ({user: {matchesDarkMode, userDarkMode}}) => ({
      userDarkMode,
      matchesDarkMode,
    }),
    shallowEqual
  );

  return userDarkMode === null ? matchesDarkMode : userDarkMode;
};

export const useDarkModeManager = (): [boolean, () => void] => {
  const dispatch = useDispatch<AppDispatch>();
  const darkMode = useIsDarkMode();

  const toggleSetDarkMode = useCallback(() => {
    dispatch(updateUserDarkMode({userDarkMode: !darkMode}));
  }, [darkMode, dispatch]);

  return [darkMode, toggleSetDarkMode];
};

export const useSession = (): Session | null => {
  const session = useSelector<AppState, Session | null>(({user: {userSession}}) => userSession, shallowEqual);

  if (!session) return null;

  const expiresAt = fromUnixTime(parseInt(session.expiresAt) || 0);
  if (isBefore(expiresAt, new Date())) {
    return null;
  }

  return session;
};

export const useSessionManager = (): {session: Session | null; login: () => void; signup: () => void} => {
  const session = useSession();
  const history = useHistory();
  const location = useLocation();

  const login = useCallback(() => {
    history.push(`/login?callback=${location.pathname}`);
  }, [history, location]);

  const signup = useCallback(() => {
    history.push(`/signup?callback=${location.pathname}`);
  }, [history, location]);

  return {session, login, signup};
};
