import {Location} from 'history';
import React, {ReactNode, Suspense} from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Box, Flex} from 'theme-ui';
import {BerModal} from '../components/Ber/BerModal';
import {EvaluationModal} from '../components/Evaluation/EvaluationModal';
import {Navbar} from '../components/Navbar/Navbar';
import {useSessionManager} from '../state/user/hooks';
import ForgetPassword from './Auth/ForgetPassword';
import Login from './Auth/Login';
import ResetPassword from './Auth/ResetPassword';
import Signup from './Auth/Signup';
import Evaluate from './Evaluate';
import NotFound from './Notfound';
import SearchNumber from './SearchNumber';
import SearchTag from './SearchTag';
import Seller from './Seller';
import Shops from './Shops';
import Shop from './Shops/Shop';

export interface LocationState {
  background: Location<LocationState>;
}

const AppWrapper = (props: {children?: ReactNode}): JSX.Element => (
  <Flex
    sx={{
      flexDirection: 'column',
      alignItems: 'flex-start',
    }}
    {...props}
  />
);

const NavbarWrapper = (props: {children?: ReactNode}): JSX.Element => (
  <Box
    id="navbarwrapper"
    sx={{
      width: ['100%'],
      // height: `${NAVBAR_HEIGH}`,
      bg: 'neutral.n0',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    }}
    {...props}
  />
);

const BodyWapper = (props: {children?: ReactNode}): JSX.Element => {
  return (
    <Flex
      id="bodywrapper"
      sx={{
        // height: `calc(100vh - ${NAVBAR_HEIGH})`,
        width: ['100%'],
        flexDirection: 'column',
        // mt: [NAVBAR_HEIGH],
      }}
      {...props}
    />
  );
};

const Updater = (): JSX.Element => {
  return <ToastContainer />;
};

const PrivateRoute = ({children, props}: {children: ReactNode; props?: any}) => {
  const {session} = useSessionManager();
  return (
    <Route
      {...props}
      render={({location}) =>
        session?.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {from: location},
            }}
          />
        )
      }
    />
  );
};

export default function App() {
  const location = useLocation<LocationState>();
  const background = location.state && location.state.background;

  return (
    <Suspense fallback={null}>
      <AppWrapper>
        <NavbarWrapper>
          <Updater />
          <Navbar />
        </NavbarWrapper>
        <BodyWapper>
          <Switch location={background || location}>
            <Route exact strict path="/" component={SearchNumber} />
            <Route exact strict path="/tags" component={SearchTag} />
            <Route exact strict path="/evaluate" component={Evaluate} />
            <Route exact strict path="/shops" component={Shops} />
            <Route exact strict path="/shops/:id" component={Shop} />
            <Route exact strict path="/login" component={Login} />
            <Route exact strict path="/signup" component={Signup} />
            <Route exact strict path="/forgetpassword" component={ForgetPassword} />
            <Route exact strict path="/resetpassword" component={ResetPassword} />
            <PrivateRoute>
              <Route exact strict path="/seller" component={Seller} />
            </PrivateRoute>
            <Route path="*" component={NotFound} />
          </Switch>

          {background && <Route exact strict path="/bers/:id" component={BerModal} />}
          {background && <Route exact strict path="/evaluate/:ber" component={EvaluationModal} />}
        </BodyWapper>
      </AppWrapper>
    </Suspense>
  );
}
