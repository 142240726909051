import {useMutation} from '@apollo/client';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import React, {useMemo, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import {Box, Button, Divider, Flex, Input, Label, Link, Select, Text} from 'theme-ui';
import {DeleteBerResult, DeleteBerVars, DELETE_BER, UpsertBerResult, UpsertBerVars, UPSERT_BER} from '../../graph/ber';
import {Ber, BerProvider} from '../../graph/types';
import {AppDispatch} from '../../state';
import {deleteBer, updateBer} from '../../state/bers/actions';
import {useSessionManager} from '../../state/user/hooks';
import {perttiyBer} from '../../utils/prettify-ber';
import {IconLineContact, IconLinkOut} from '../Icon/Icon';

export const BerFull = ({ber, onDismiss}: {ber?: Ber; onDismiss?: () => void}) => {
  const lastOnlineAt = ber?.shop.lastLoginAt ? formatDistanceToNow(new Date(ber.shop.lastLoginAt)) : '-';
  const {session} = useSessionManager();
  const dispatch = useDispatch<AppDispatch>();

  const [price, setPrice] = useState(`${ber?.price}`);
  const [provider, setProvider] = useState(ber?.provider || '');
  const isBerOnwer = useMemo(() => {
    if (!session) return false;

    return session.shop?.id === ber?.shop.id;
  }, [session, ber]);

  const [mutateUpsertBer] = useMutation<UpsertBerResult, UpsertBerVars>(UPSERT_BER, {
    onError: (error) => {
      toast(error.message, {type: 'error'});
    },
    onCompleted: (data) => {
      // if (onDismiss) onDismiss();

      if (!data || !ber) return;

      const {id, price, provider} = data.ber;
      const page = `shop_${ber.shop.id}`;
      dispatch(updateBer({page, id, price, provider}));

      toast('แก้ไขเบอร์สำเร็จ');
    },
  });

  const [mutateDeleteBer] = useMutation<DeleteBerResult, DeleteBerVars>(DELETE_BER, {
    onError: (error) => {
      toast(error.message, {type: 'error'});
    },
    onCompleted: () => {
      if (onDismiss) onDismiss();

      if (!ber) return;

      const page = `shop_${ber.shop.id}`;
      dispatch(deleteBer({page, id: ber.id}));
    },
  });

  const handleUpsertBer = () => {
    if (!ber) return;

    let priceNum = 0;
    try {
      priceNum = parseInt(price);
    } catch (error) {
      toast('ราคาไม่ถูกต้อง', {type: 'error'});
    }

    mutateUpsertBer({
      variables: {
        input: {
          number: ber.number,
          price: priceNum,
          provider: provider as BerProvider,
        },
      },
    });
  };

  const handleDeleteBer = () => {
    if (!confirm(`คอนเฟิร์มลบเบอร์ ${ber?.number && perttiyBer(ber.number)}`)) return;

    if (!ber) return;

    mutateDeleteBer({
      variables: {
        input: {
          id: ber.id,
        },
      },
    });
  };

  return (
    <Box>
      <Flex sx={{flexDirection: 'column'}}>
        <Text variant="title">{(ber?.number && perttiyBer(ber.number)) || <Skeleton />}</Text>

        {!isBerOnwer ? (
          <Flex sx={{flexDirection: 'row', mt: ['12px']}}>
            <Text variant="body" mr={['12px']}>
              ราคา {ber?.price.toLocaleString() || <Skeleton width="20px" />}
            </Text>
            <Text variant="body" mr={['12px']}>
              •
            </Text>
            <Text variant="body" mr={['12px']}>
              ผลรวม {ber?.sum || <Skeleton width="20px" />}
            </Text>
            <Text variant="body" mr={['12px']}>
              •
            </Text>
            <Text variant="body" mr={['12px']}>
              {ber?.provider || <Skeleton width="20px" />}
            </Text>
          </Flex>
        ) : (
          <Box>
            <Flex sx={{flexDirection: 'row', mt: ['12px'], gap: ['12px']}}>
              <Box sx={{width: '80px'}}>
                <Label htmlFor="ผลรวม">ผลรวม</Label>
                <Input name="sum" id="sum" value={ber?.sum} disabled />
              </Box>

              <Box>
                <Label htmlFor="price">ราคา</Label>
                <Input
                  name="price"
                  id="price"
                  value={price}
                  inputMode="numeric"
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Box>

              <Box>
                <Label htmlFor="provider">เครือข่าย</Label>
                <Select
                  id="provider"
                  defaultValue={provider}
                  onChange={(e) => {
                    setProvider(e.target.value);
                  }}
                  sx={{width: '125px'}}
                >
                  <option value={BerProvider.AIS}>AIS</option>
                  <option value={BerProvider.DTAC}>DTAC</option>
                  <option value={BerProvider.TRUE}>TRUE</option>
                  <option value={BerProvider.PENGUIN}>PENGUIN</option>
                  <option value={BerProvider.TOT}>TOT</option>
                  <option value={BerProvider.MYBYCAT}>MYBYCAT</option>
                  <option value={BerProvider.IMOBILE}>IMOBILE</option>
                  <option value={BerProvider.IEC}>IEC</option>
                </Select>
              </Box>
            </Flex>
            <Flex sx={{flexDirection: 'row', justifyContent: 'space-between', mt: ['12px']}}>
              <Button variant="text" onClick={handleDeleteBer}>
                <Text variant="caption" color="accent.b1000">
                  ลบเบอร์
                </Text>
              </Button>
              <Button variant="text" onClick={handleUpsertBer}>
                <Text variant="caption" color="accent.b1000">
                  อัพเดท
                </Text>
              </Button>
            </Flex>
          </Box>
        )}

        <Divider />

        <Flex sx={{alignItems: 'center'}}>
          <Text variant="header" sx={{mr: ['8px']}}>
            {ber?.shop.name || <Skeleton width="200px" />}
          </Text>
          {ber?.shop.id && (
            <Link href={`/shops/${ber?.shop.id}`} target="_blank">
              <Button variant="text">
                <IconLinkOut />
              </Button>
            </Link>
          )}
        </Flex>
        <Flex sx={{alignItems: 'center', mt: ['4px']}}>
          <Text variant="body" sx={{mr: ['8px']}}>
            line: {ber?.shop.lineId || <Skeleton width="170px" />}
          </Text>
          {ber?.shop.lineId && (
            <Button variant="text">
              <IconLineContact />
            </Button>
          )}
        </Flex>
        <Text variant="caption" sx={{mt: ['4px'], color: 'text.t100'}}>
          ออนไลน์ล่าสุด: {lastOnlineAt}
        </Text>
      </Flex>

      <Divider />

      <Flex sx={{flexDirection: 'column'}}>
        <Text variant="header">จุดเด่นของเบอร์นี้</Text>
        <Text variant="body" sx={{mt: ['12px']}}>
          {ber?.evaluations?.map((e) => e.pros).join(' ') || <Skeleton width="100%" count={10} />}
        </Text>
      </Flex>

      <Divider />

      <Flex sx={{flexDirection: 'column'}}>
        <Text variant="header">คำทำนายแบบละเอียด</Text>
        {ber?.evaluations?.map(({description, weight, pair}, i) => (
          <Text key={i} variant="body" sx={{mt: ['12px']}}>
            {description}{' '}
            <Text color="text.t100">
              [{pair}/{pair.split('').reverse().join('')}] {weight}%
            </Text>
          </Text>
        )) || <Skeleton width="100%" count={15} />}
      </Flex>
    </Box>
  );
};
