import {useMutation} from '@apollo/client';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Box, Container, Flex, Input, Label, Text} from 'theme-ui';
import {ForgetPasswordResult, ForgetPasswordVars, FORGET_PASSWORD} from '../../graph/auth';

export default function ForgetPassword() {
  const [email, setEmail] = useState<string>('');
  const [success, setSuccess] = useState(false);
  const [fetchData] = useMutation<ForgetPasswordResult, ForgetPasswordVars>(FORGET_PASSWORD, {
    variables: {
      email,
    },
    onCompleted: (data) => {
      if (!data) return;

      setSuccess(true);
    },
  });

  const submit = (e: any) => {
    e.preventDefault();

    fetchData();
  };

  return (
    <Box sx={{bg: 'neutral.n10', height: '100vh'}}>
      <Container>
        <Flex sx={{justifyContent: 'center', alignItem: 'center'}}>
          {!success && (
            <Box
              as="form"
              onSubmit={submit}
              sx={{width: ['303px'], bg: 'neutral.n0', my: '36px', p: '16px', borderRadius: '8px'}}
            >
              <Text variant="title">จำรหัสผ่านไม่ได้</Text>
              <Label htmlFor="email" sx={{mt: '16px'}}>
                อีเมล์ที่เคยใช้สมัคร
              </Label>
              <Input name="email" id="email" sx={{mt: '8px'}} onChange={(e) => setEmail(e.target.value)} autoFocus />

              <Box>
                <Text variant="caption">เบอร์ฮิตจะส่งลิงค์ในการรีเซ็ตรหัสผ่านไปที่อีเมล์ที่ระบุ</Text>
              </Box>

              <Input variant="submit" type="submit" value="ส่งวิธีรีเซ็ท" sx={{mt: '16px', bg: 'accent.b1000'}} />

              <Box sx={{mt: '16px'}}>
                <Text variant="caption">มีบัญชีอยู่แล้ว?</Text>{' '}
                <Link to="/login">
                  <Text variant="caption" color="accent.b1000" sx={{cursor: 'pointer'}}>
                    เข้าสู่ระบบ
                  </Text>
                </Link>
              </Box>
            </Box>
          )}

          {success && (
            <Box sx={{width: ['303px'], bg: 'neutral.n0', my: '36px', p: '16px', borderRadius: '8px'}}>
              <Flex sx={{flexDirection: 'column'}}>
                <Text variant="title" color="accent.b1000">
                  จำรหัสผ่านไม่ได้
                </Text>
                <Text sx={{mt: '16px'}}>กรุณาเชคอีเมล {email}</Text>
              </Flex>
            </Box>
          )}
        </Flex>
      </Container>
    </Box>
  );
}
