import {useMutation} from '@apollo/client';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Box, Container, Flex, Input, Label, Text} from 'theme-ui';
import {SignupEmailResult, SignupEmailVars, SIGNUP_EMAIL} from '../../graph/auth';
import {AppDispatch} from '../../state';
import {updateUserSession} from '../../state/user/actions';

export default function Signup() {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [shopName, setShopName] = useState<string>('');
  const [shopLineId, setShopLineId] = useState<string>('');
  const [fetchData] = useMutation<SignupEmailResult, SignupEmailVars>(SIGNUP_EMAIL, {
    variables: {
      email,
      password,
      shopName,
      shopLineId,
    },
    onCompleted: (data) => {
      if (!data) return;

      dispatch(updateUserSession({session: data.session}));

      history.push('/seller');

      // const callback = router.query.callback;

      // if (typeof callback === 'string') {
      //   router.push(callback as string, callback as string);
      // } else {
      //   router.push('/', '/');
      // }
    },
    onError: (error) => {
      toast(error.message, {type: 'error'});
    },
  });

  const submit = (e: any) => {
    e.preventDefault();

    fetchData();
  };

  return (
    <Box sx={{bg: 'neutral.n10', height: '100vh'}}>
      <Container>
        <Flex sx={{justifyContent: 'center', alignItem: 'center'}}>
          <Box
            as="form"
            onSubmit={submit}
            sx={{width: ['303px'], bg: 'neutral.n0', my: '36px', p: '16px', borderRadius: '8px'}}
          >
            <Flex sx={{flexDirection: 'row', justifyContent: 'space-between'}}>
              <Flex sx={{flexDirection: 'column'}}>
                <Text variant="title">สมัครบัญชีผู้ขาย</Text>
                <Text variant="body" sx={{mt: '4px'}}>
                  เพื่อประกาศขายเบอร์
                </Text>
              </Flex>
              <Box>
                <Text variant="callout">สมัครฟรี!</Text>
              </Box>
            </Flex>
            <Label htmlFor="email" sx={{mt: '16px'}}>
              อีเมล
            </Label>
            <Input name="email" id="email" sx={{mt: '8px'}} onChange={(e) => setEmail(e.target.value)} />

            <Label htmlFor="password" sx={{mt: '16px'}}>
              รหัสผ่าน
            </Label>
            <Input
              type="password"
              name="password"
              id="password"
              sx={{mt: '8px'}}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Label htmlFor="shopname" sx={{mt: '16px'}}>
              ชื่อร้าน
            </Label>
            <Input name="shopname" id="shopname" sx={{mt: '8px'}} onChange={(e) => setShopName(e.target.value)} />

            <Label htmlFor="lineId" sx={{mt: '16px'}}>
              Line ID
            </Label>
            <Input name="lineId" id="lineId" sx={{mt: '8px'}} onChange={(e) => setShopLineId(e.target.value)} />

            <Input variant="submit" type="submit" value="สมัครบัญชีผู้ขาย" sx={{mt: '16px', bg: 'accent.b1000'}} />

            <Box sx={{mt: '16px'}}>
              <Text variant="caption">มีบัญชีอยู่แล้ว?</Text>{' '}
              <Link to="/login">
                <Text variant="caption" color="accent.b1000" sx={{cursor: 'pointer'}}>
                  เข้าสู่ระบบ
                </Text>
              </Link>
            </Box>

            <Box sx={{mt: '16px'}}>
              <Text
                variant="caption"
                sx={{color: 'text.t100', fontSize: '12px', lineHeight: '19px', letterSpacing: '0.02em'}}
              >
                โดยการกด &quot;สมัคร&quot; เราถือว่าคุณยอมรับข้อตกลง ในการใช้งาน และ นโยบายความเป็นส่วนตัว กับทาง
                เว็บไซต์เบอร์ฮิต
              </Text>
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}
