import {ApolloClient, ApolloLink, from, InMemoryCache} from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client';
import store from '../state';

const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;

if (!GRAPHQL_ENDPOINT) {
  throw new Error('REACT_APP_GRAPHQL_ENDPOINT environment variable not defined ');
}

// const httpLink = new HttpLink({uri: GRAPHQL_ENDPOINT});
const uploadLink = createUploadLink({uri: GRAPHQL_ENDPOINT});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const {
    user: {userSession},
  } = store.getState();
  operation.setContext(({headers = {}}) => ({
    headers: {
      ...headers,
      authorization: userSession?.accessToken ? `Bearer ${userSession.accessToken}` : null,
    },
  }));

  return forward(operation);
});

export const client = new ApolloClient({
  link: from([authMiddleware, uploadLink]),
  cache: new InMemoryCache(),
});
