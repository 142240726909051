import '@reach/menu-button/styles.css';
import React, {FC, useState} from 'react';
import {Link} from 'react-router-dom';
import {Box, Button, Container, Flex, Image, Text} from 'theme-ui';
import {NAVBAR_HEIGH} from '../../constant';
import {IconClose, IconMenu} from '../Icon/Icon';

export interface NavbarProps {
  height?: string;
}

export const Navbar: FC<NavbarProps> = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <Container variant="navbar">
      <Flex
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
          width: ['100%'],
          p: ['12px'],
        }}
      >
        <Link to={{pathname: '/'}}>
          <Flex sx={{flexDirection: 'row', alignItems: 'center', cursor: 'pointer'}}>
            <Image src="/images/LOGO-FLAT 1.png" alt="berhit log" sx={{height: '36px'}} />
            <Flex sx={{flexDirection: 'column', pl: ['10px']}}>
              <Text variant="brandText">เบอร์ฮิต</Text>
              <Text variant="brandSubtext" sx={{mt: ['4px']}}>
                หาเบอร์ถูกใจได้ง่ายๆที่เดียวที่เบอร์ฮิต
              </Text>
            </Flex>
          </Flex>
        </Link>
        {/* for destop */}
        <Box
          sx={{
            flexDirection: 'row',
            display: ['none', 'flex'],
          }}
        >
          <Link to={{pathname: '/'}}>
            <Button variant="menu">
              <Text variant="header">หาเบอร์ตามตัวเลข</Text>
            </Button>
          </Link>
          <Link to={{pathname: '/tags'}}>
            <Button variant="menu">
              <Text variant="header">หาเบอร์ตามความหมาย</Text>
            </Button>
          </Link>
          <Link to={{pathname: '/evaluate'}}>
            <Button variant="menu">
              <Text variant="header">ทำนายเบอร์</Text>
            </Button>
          </Link>
          <Link to={{pathname: '/shops'}}>
            <Button variant="menu">
              <Text variant="header">ร้านขายเบอร์ทั้งหมด</Text>
            </Button>
          </Link>
          <Link to={{pathname: '/seller'}}>
            <Button variant="menu">
              <Text variant="header">ผู้ขาย</Text>
            </Button>
          </Link>
        </Box>

        {/* for mobile */}
        <Box onClick={() => setShowMenu(!showMenu)} sx={{height: '36px', pt: ['4px'], display: ['block', 'none']}}>
          {showMenu ? <IconClose /> : <IconMenu />}
        </Box>
        {showMenu && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              mt: NAVBAR_HEIGH,
              py: '12px',
              px: '4px',
              bg: 'neutral.n0',
              display: showMenu ? 'flex' : 'none',
              flexDirection: 'column',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              zIndex: 10,
            }}
          >
            <Link to={{pathname: '/'}} onClick={() => setShowMenu(false)}>
              <Button variant="menu" mb="8px">
                <Text variant="header">หาเบอร์ตามตัวเลข</Text>
              </Button>
            </Link>
            <Link to={{pathname: '/tags'}} onClick={() => setShowMenu(false)}>
              <Button variant="menu" mb="8px">
                <Text variant="header">หาเบอร์ตามความหมาย</Text>
              </Button>
            </Link>
            <Link to={{pathname: '/evaluate'}} onClick={() => setShowMenu(false)}>
              <Button variant="menu" mb="8px">
                <Text variant="header">ทำนายเบอร์</Text>
              </Button>
            </Link>
            <Link to={{pathname: '/shops'}} onClick={() => setShowMenu(false)}>
              <Button variant="menu" mb="8px">
                <Text variant="header">ร้านขายเบอร์ทั้งหมด</Text>
              </Button>
            </Link>
            <Link to={{pathname: '/seller'}} onClick={() => setShowMenu(false)}>
              <Button variant="menu" mb="8px">
                <Text variant="header">ผู้ขาย</Text>
              </Button>
            </Link>
          </Box>
        )}
      </Flex>
    </Container>
  );
};
