import {useLazyQuery, useQuery} from '@apollo/client';
import _ from 'lodash';
import React, {useCallback, useState} from 'react';
import {FiX} from 'react-icons/fi';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {Box, Button, Container, Flex, Grid, Text} from 'theme-ui';
import {BerRow, columns} from '../components/Ber/BerRow';
import {NumberFilter} from '../components/Filter/NumberFilter';
import {FilterDialog} from '../components/Filter/PriceProviderFilter';
import {TagFilter} from '../components/Filter/TagFilter';
import {IconNotFound} from '../components/Icon/Icon';
import {ListLoader} from '../components/Loading/BerListLoader';
import {ListBersResult, ListBersVars, LIST_BERS, LIST_SIMILAR_BERS} from '../graph/ber';
import {BerProvider, BerWhereInput} from '../graph/types';
import {AppDispatch} from '../state';
import {addListBersQueryResult, resetListBersQueryResult} from '../state/bers/actions';
import {useListBersByPage} from '../state/bers/hooks';
import {LocationState} from './App';

export default function SearchTag() {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation<LocationState>();
  const page = 'bers_index';
  const {bers} = useListBersByPage(page);
  const [variables, setVariable] = useState<ListBersVars>({});
  const [price, setPrice] = useState(0);
  const [providers, setProviders] = useState<BerProvider[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const toggleShowFilter = () => setShowFilter(!showFilter);

  useQuery<ListBersResult, ListBersVars>(LIST_BERS, {
    variables,
    onCompleted: (data) => {
      if (!data) return;

      dispatch(addListBersQueryResult({page, result: data}));

      if (data.bers.totalCount === 0) {
        fetchSimilarBers({variables});
      }
    },
  });

  const [fetchSimilarBers, {data: similarBers}] = useLazyQuery<ListBersResult, ListBersVars>(LIST_SIMILAR_BERS);

  const handlePriceProviderFilterUpdate = useCallback(
    (filteredPrice, filteredProviders) => {
      setPrice(filteredPrice);
      setProviders(filteredProviders);

      const newVars = {
        ...variables,
        page: 1,
        where: {...variables.where, priceLT: filteredPrice, providers: filteredProviders},
      };

      if (!_.isEqual(variables, newVars)) {
        dispatch(resetListBersQueryResult({page}));
        setVariable(newVars);
      }
    },
    [dispatch, variables]
  );

  const handleNumberFilterUpdate = useCallback(
    (where: BerWhereInput) => {
      const newVars = {
        ...variables,
        page: 1,
        where: {...where, priceLT: variables.where?.priceLT, providers: variables.where?.providers},
      };

      if (!_.isEqual(variables, newVars)) {
        dispatch(resetListBersQueryResult({page}));
        setVariable(newVars);
      }
    },
    [dispatch, variables]
  );

  const handleTagFilterUpdate = useCallback(
    (where: BerWhereInput) => {
      const newVars = {
        ...variables,
        page: 1,
        where: {...where, tags: where.tags, priceLT: variables.where?.priceLT, providers: variables.where?.providers},
      };

      if (!_.isEqual(variables, newVars)) {
        dispatch(resetListBersQueryResult({page}));
        setVariable(newVars);
      }
    },
    [dispatch, variables]
  );

  const next = useCallback(() => {
    setVariable({...variables, page: (variables?.page || 1) + 1});
  }, [variables]);

  const totalBerText =
    bers?.totalCount >= 0 ? <span>{bers.totalCount.toLocaleString()} เบอร์</span> : <Skeleton width="50px"></Skeleton>;

  return (
    <>
      <Box sx={{boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'}}>
        <Container sx={{p: ['12px']}}>
          <TagFilter onFilterUpdate={handleTagFilterUpdate} />

          <Flex sx={{flexDirection: 'row', justifyContent: 'flex-end'}}>
            <Button variant="text" onClick={toggleShowFilter} sx={{p: 0}}>
              {!showFilter && (
                <Text variant="caption" color="accent.r1000">
                  กำหนดด้วยตัวเลข
                </Text>
              )}
              {showFilter && (
                <>
                  <FiX size="14px" />
                  <Text variant="caption">ปิด</Text>
                </>
              )}
            </Button>
          </Flex>

          <Box sx={{display: showFilter ? 'block' : 'none'}}>
            <NumberFilter onFilterUpdate={handleNumberFilterUpdate} />
          </Box>
        </Container>
      </Box>

      {bers?.totalCount > 0 && (
        <Container>
          <Flex sx={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline', p: ['12px']}}>
            <Text variant="header">เจอทั้งหมด {totalBerText}</Text>
            <FilterDialog
              defaultPrice={variables.where?.priceLT}
              defaultProviders={variables.where?.providers?.reduce((o, key) => Object.assign(o, {[key]: true}), {})}
              isFiltered={price > 0 || providers.length > 0}
              onUpdateFilter={handlePriceProviderFilterUpdate}
            />
          </Flex>

          <Box sx={{px: ['12px']}}>
            <Grid gap={0} columns={columns} sx={{color: 'text.t100'}}>
              <Text variant="caption" color="text.t100">
                เบอร์
              </Text>
              <Text variant="caption" color="text.t100" sx={{textAlign: 'center'}}>
                ผลรวม
              </Text>
              <Text variant="caption" color="text.t100" sx={{textAlign: 'right'}}>
                ราคา
              </Text>
              <Text variant="caption" color="text.t100" sx={{textAlign: 'right'}}>
                เครือข่าย
              </Text>
            </Grid>

            {bers && (
              <InfiniteScroll
                dataLength={bers.edges.length} //This is important field to render the next data
                next={next}
                hasMore={bers.pageInfo.hasNextPage}
                loader={<ListLoader />}
                endMessage={<Text></Text>}
              >
                <Grid gap={0} columns={[`1fr`]}>
                  {bers.edges.map((edge, i) => (
                    <BerRow key={i} ber={edge.node} location={location} />
                  ))}
                </Grid>
              </InfiniteScroll>
            )}
          </Box>
        </Container>
      )}

      {bers?.totalCount === 0 && (
        <Container>
          <Flex sx={{flexDirection: 'row', m: '38px', justifyContent: 'center'}}>
            <IconNotFound />
          </Flex>

          {similarBers && similarBers.bers.totalCount > 0 && (
            <>
              <Flex sx={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline', p: ['12px']}}>
                <Text variant="header">เบอร์ใกล้เคียง {similarBers.bers.totalCount} เบอร์</Text>
              </Flex>

              <Box sx={{px: ['12px']}}>
                <Grid gap={0} columns={columns} sx={{color: 'text.t100'}}>
                  <Text variant="caption" color="text.t100">
                    เบอร์
                  </Text>
                  <Text variant="caption" color="text.t100" sx={{textAlign: 'center'}}>
                    ผลรวม
                  </Text>
                  <Text variant="caption" color="text.t100" sx={{textAlign: 'right'}}>
                    ราคา
                  </Text>
                  <Text variant="caption" color="text.t100" sx={{textAlign: 'right'}}>
                    เครือข่าย
                  </Text>
                </Grid>

                {similarBers && (
                  <Grid gap={0} columns={[`1fr`]}>
                    {similarBers.bers.edges.map((edge, i) => (
                      <BerRow key={i} ber={edge.node} location={location} />
                    ))}
                  </Grid>
                )}
              </Box>
            </>
          )}
        </Container>
      )}
    </>
  );
}
