import styled from '@emotion/styled';
import {DialogContent, DialogOverlay} from '@reach/dialog';
import '@reach/dialog/styles.css';
import React, {FC, useCallback, useState} from 'react';
import {Box, Button, Flex, Label, Select, Text} from 'theme-ui';
import {BerProvider} from '../../graph/types';
import {Checkbox2} from '../Checkbox/Checkbox2';
import {IconFilter, IconReset} from '../Icon/Icon';

const StyledDialogOverlay = styled(DialogOverlay)`
  /* padding: 12px; */
`;

const StyledDialogContent = styled(DialogContent)`
  width: 267px;
  padding: 24px;
  border-radius: 8px;
`;

export interface FilterDialogProps {
  defaultProviders?: {[key: string]: boolean};
  defaultPrice?: number;
  isFiltered: boolean;
  onUpdateFilter?: (price: number, providers: BerProvider[]) => void;
}

export const FilterDialog: FC<FilterDialogProps> = ({onUpdateFilter, isFiltered, defaultProviders, defaultPrice}) => {
  const [price, setPrice] = useState(defaultPrice ?? 0);
  const [providers, setProviders] = useState<{[key: string]: boolean}>(defaultProviders ?? {});

  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  const handleResetFilter = useCallback(() => {
    setProviders({
      [BerProvider.AIS]: false,
      [BerProvider.DTAC]: false,
      [BerProvider.TRUE]: false,
      [BerProvider.MYBYCAT]: false,
      [BerProvider.TOT]: false,
      [BerProvider.PENGUIN]: false,
      [BerProvider.IMOBILE]: false,
      [BerProvider.IEC]: false,
    });
    setPrice(0);
    if (onUpdateFilter) {
      onUpdateFilter(0, []);
    }
    close();
  }, [onUpdateFilter]);

  const handleFilter = useCallback(() => {
    if (onUpdateFilter) {
      onUpdateFilter(
        price,
        Object.entries(providers)
          .filter(([, value]) => value)
          .map(([key]) => key as BerProvider)
      );
    }
    close();
  }, [onUpdateFilter, price, providers]);

  return (
    <Box>
      <Button variant={isFiltered ? 'tagReverse' : 'tag'} onClick={open}>
        <IconFilter stroke={isFiltered ? '#fff' : '#414141'} />
        กรอง
      </Button>

      <StyledDialogOverlay isOpen={showDialog} onDismiss={close}>
        <StyledDialogContent aria-label="filter">
          <Flex sx={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <Text variant="header">กรองผลการหาเบอร์</Text>
            <Button variant="text" onClick={() => handleResetFilter()}>
              <IconReset />
              รีเซ็ท
            </Button>
          </Flex>

          <Flex sx={{flexDirection: 'column', mt: ['12px']}}>
            <Text variant="caption" sx={{mb: '8px'}}>
              ราคาไม่เกิน
            </Text>
            <Select
              defaultValue={price}
              onChange={(e) => {
                setPrice(parseInt(e.target.value));
              }}
              sx={{
                border: '1px solid #C8C8C8',
                borderRadius: '6px',
                color: 'neutral.n100',
              }}
            >
              <option value={0}></option>
              <option value={500}>500 บาท</option>
              <option value={1000}>1,000 บาท</option>
              <option value={2000}>2,000 บาท</option>
              <option value={5000}>5,000 บาท</option>
              <option value={10000}>10,000 บาท</option>
            </Select>

            <Flex sx={{flexDirection: 'column', mt: ['12px']}}>
              <Text variant="caption" sx={{mb: '8px'}}>
                เครือข่าย
              </Text>
              <Box>
                <Label sx={{mb: ['12px']}}>
                  <Checkbox2
                    checked={providers[BerProvider.AIS]}
                    onChange={(e) => setProviders({...providers, [BerProvider.AIS]: e.target.checked})}
                  />
                  AIS
                </Label>
              </Box>
              <Label sx={{mb: ['12px']}}>
                <Checkbox2
                  checked={providers[BerProvider.DTAC]}
                  onChange={(e) => setProviders({...providers, [BerProvider.DTAC]: e.target.checked})}
                />
                DTAC
              </Label>
              <Label sx={{mb: ['12px']}}>
                <Checkbox2
                  checked={providers[BerProvider.TRUE]}
                  onChange={(e) => setProviders({...providers, [BerProvider.TRUE]: e.target.checked})}
                />
                TRUE
              </Label>
              <Label sx={{mb: ['12px']}}>
                <Checkbox2
                  checked={providers[BerProvider.MYBYCAT]}
                  onChange={(e) => setProviders({...providers, [BerProvider.MYBYCAT]: e.target.checked})}
                />
                My by CAT
              </Label>
              <Label sx={{mb: ['12px']}}>
                <Checkbox2
                  checked={providers[BerProvider.TOT]}
                  onChange={(e) => setProviders({...providers, [BerProvider.TOT]: e.target.checked})}
                />
                TOT
              </Label>
              <Label sx={{mb: ['12px']}}>
                <Checkbox2
                  checked={providers[BerProvider.PENGUIN]}
                  onChange={(e) => setProviders({...providers, [BerProvider.PENGUIN]: e.target.checked})}
                />
                Penguin
              </Label>
              <Label sx={{mb: ['12px']}}>
                <Checkbox2
                  checked={providers[BerProvider.IMOBILE]}
                  onChange={(e) => setProviders({...providers, [BerProvider.IMOBILE]: e.target.checked})}
                />
                i-Mobile
              </Label>
              <Label sx={{mb: ['12px']}}>
                <Checkbox2
                  checked={providers[BerProvider.IEC]}
                  onChange={(e) => setProviders({...providers, [BerProvider.IEC]: e.target.checked})}
                />
                IEC
              </Label>
            </Flex>

            <Button variant="primaryReverse" sx={{mt: '24px'}} onClick={handleFilter}>
              <Text variant="header" color="accent.r1000">
                แสดงเบอร์
              </Text>
            </Button>
          </Flex>
        </StyledDialogContent>
      </StyledDialogOverlay>
    </Box>
  );
};
