import React from 'react';
import {Box, Container, Flex, Text} from 'theme-ui';

export default function NotFound() {
  return (
    <Box sx={{bg: 'neutral.n10', height: '100vh'}}>
      <Container>
        <Flex sx={{justifyContent: 'center', alignItem: 'center'}}>
          <Text variant="header">404 Notfound</Text>
        </Flex>
      </Container>
    </Box>
  );
}
