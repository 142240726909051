import {useQuery} from '@apollo/client';
import {formatDistanceToNow} from 'date-fns';
import {th} from 'date-fns/locale';
import _ from 'lodash';
import React, {useCallback, useState} from 'react';
import CsvDownloader from 'react-csv-downloader';
import {FiX} from 'react-icons/fi';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import {useDispatch} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Box, Button, Card, Container, Flex, Grid, Text} from 'theme-ui';
import {BerRow, columns} from '../../components/Ber/BerRow';
import {NumberFilter} from '../../components/Filter/NumberFilter';
import {FilterDialog} from '../../components/Filter/PriceProviderFilter';
import {IconLineContact} from '../../components/Icon/Icon';
import {ListLoader} from '../../components/Loading/BerListLoader';
import {client} from '../../connectors/graphql';
import {STATIC_ASSET_PREFIX} from '../../constant';
import {ListBersResult, ListBersVars, LIST_BERS} from '../../graph/ber';
import {GetShopResult, GetShopVars, GET_SHOP} from '../../graph/shop';
import {BerProvider, BerWhereInput, PageInfo} from '../../graph/types';
import {AppDispatch} from '../../state';
import {addListBersQueryResult, resetListBersQueryResult} from '../../state/bers/actions';
import {useListBersByPage} from '../../state/bers/hooks';
import {useSessionManager} from '../../state/user/hooks';
import {LocationState} from '../App';

type BerCsv = {
  number: string;
  price: string;
  provider: string;
};

const headers = [
  {id: 'number', displayName: 'number'},
  {id: 'price', displayName: 'price'},
  {id: 'provider', displayName: 'provider'},
];

export default function Shop() {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation<LocationState>();
  const {id} = useParams<{id: string}>();
  const shopId = parseInt(id);
  const page = `shop_${id}`;
  const {bers} = useListBersByPage(page);
  const [variables, setVariables] = useState<ListBersVars>({where: {shopId}});
  const [price, setPrice] = useState(0);
  const [providers, setProviders] = useState<BerProvider[]>([]);
  const [showFilter, setShowFilter] = useState(false);

  const {session} = useSessionManager();

  const {data: shopResult} = useQuery<GetShopResult, GetShopVars>(GET_SHOP, {
    variables: {id: shopId},
  });

  useQuery<ListBersResult, ListBersVars>(LIST_BERS, {
    variables,
    onCompleted: (data) => {
      if (!data) return;

      dispatch(addListBersQueryResult({page, result: data}));
    },
  });

  const handlePriceProviderFilterUpdate = useCallback(
    (filteredPrice, filteredProviders) => {
      setPrice(filteredPrice);
      setProviders(filteredProviders);

      const newVars = {
        ...variables,
        page: 1,
        where: {...variables.where, shopId, priceLT: filteredPrice, providers: filteredProviders},
      };

      if (!_.isEqual(variables, newVars)) {
        dispatch(resetListBersQueryResult({page}));
      }

      setVariables(newVars);
    },
    [dispatch, page, variables, shopId]
  );

  const handleNumberFilterUpdate = useCallback(
    (where: BerWhereInput) => {
      dispatch(resetListBersQueryResult({page}));
      setVariables({
        ...variables,
        page: 1,
        where: {...where, shopId, priceLT: variables.where?.priceLT, providers: variables.where?.providers},
      });
    },
    [dispatch, variables, page, shopId]
  );

  const handleDownloadCsv = async (): Promise<BerCsv[]> => {
    let pageInfo: PageInfo = {
      hasNextPage: true,
      hasPreviousPage: false,
      nextPage: 1,
      totalPage: 0,
    };
    const dataCsv: BerCsv[] = [];

    while (pageInfo.hasNextPage) {
      try {
        const berConn = await client
          .query<ListBersResult, ListBersVars>({
            query: LIST_BERS,
            variables: {
              page: pageInfo.nextPage,
              where: {
                shopId,
              },
            },
          })
          .then(({data: {bers}}) => {
            return bers;
          });

        dataCsv.push(
          ...berConn.edges.map(({node: {number, price, provider}}) => ({number, price: `${price}`, provider} as BerCsv))
        );
        pageInfo = berConn.pageInfo;
      } catch (error) {
        toast('มีข้อผิพลาด กรุณาลองใหม่อีกครั้ง', {type: 'error'});
        pageInfo.hasNextPage = false;
      }
    }

    return dataCsv;
  };

  const next = useCallback(() => {
    setVariables({...variables, page: (variables?.page || 1) + 1});
  }, [variables]);

  const lastOnlineAt = shopResult?.shop.lastLoginAt
    ? formatDistanceToNow(new Date(shopResult.shop.lastLoginAt), {locale: th})
    : '';

  const totalBerText =
    bers?.totalCount >= 0 ? <span>{bers.totalCount.toLocaleString()} เบอร์</span> : <Skeleton width="50px"></Skeleton>;

  const toggleShowFilter = () => setShowFilter(!showFilter);

  const pictureUrl = `${STATIC_ASSET_PREFIX ?? ''}/${shopResult?.shop.pictureUrl}`;

  return (
    <>
      <Box sx={{boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'}}>
        <Container>
          <Flex sx={{flexDirection: 'row', p: ['12px'], pb: ['0']}}>
            <Card
              variant="shopInfo"
              sx={{
                cursor: 'pointer',
                backgroundImage: `url(${pictureUrl})`,
                backgroundSize: '93px 93px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center top',
                width: '93px',
                height: '93px',
              }}
            ></Card>

            <Flex sx={{flexDirection: 'column', ml: '12px', flexGrow: 1}}>
              <Text variant="header">ขายโดยร้าน {shopResult?.shop.name || <Skeleton />}</Text>
              <Flex sx={{alignItems: 'center', mt: ['4px']}}>
                <Text variant="body" sx={{mr: ['8px']}}>
                  line: {shopResult?.shop.lineId.split(' ')[0] || <Skeleton />}
                </Text>
                <Button variant="text">
                  <IconLineContact />
                </Button>
              </Flex>
              <Text variant="body" sx={{mt: ['4px']}}>
                โทร: {shopResult?.shop.tel || <Skeleton />}
              </Text>
              <Text variant="caption" sx={{mt: ['4px'], color: 'text.t100'}}>
                ออนไลน์ล่าสุด: {lastOnlineAt}
              </Text>
            </Flex>
          </Flex>

          <Flex sx={{flexDirection: 'row', justifyContent: 'space-between', px: ['12px'], mb: ['8px']}}>
            {session?.user ? (
              <CsvDownloader datas={handleDownloadCsv} columns={headers} filename={`berhit_bers_shop_${shopId}.csv`}>
                <Button variant="text" sx={{mt: ['4px'], p: ['0px'], color: 'accent.r1000'}}>
                  <Text variant="caption" color="accent.r1000">
                    ดาวโหลดไฟล์ .csv
                  </Text>
                </Button>
              </CsvDownloader>
            ) : (
              <Box />
            )}
            <Button variant="text" sx={{mt: ['4px'], p: ['0px'], color: 'accent.r1000'}} onClick={toggleShowFilter}>
              {!showFilter && (
                <Text variant="caption" color="accent.r1000">
                  หาเบอร์ในร้านนี้
                </Text>
              )}
              {showFilter && (
                <>
                  <FiX size="14px" />
                  <Text variant="caption" color="accent.r1000">
                    ปิด
                  </Text>
                </>
              )}
            </Button>
          </Flex>

          <Box sx={{display: showFilter ? 'block' : 'none', p: ['12px']}}>
            <NumberFilter onFilterUpdate={handleNumberFilterUpdate} />
          </Box>
        </Container>
      </Box>

      <Container>
        <Flex sx={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline', p: ['12px']}}>
          <Text variant="header">เจอทั้งหมด {totalBerText}</Text>
          <FilterDialog
            defaultPrice={variables.where?.priceLT}
            defaultProviders={variables.where?.providers?.reduce((o, key) => Object.assign(o, {[key]: true}), {})}
            isFiltered={price > 0 || providers.length > 0}
            onUpdateFilter={handlePriceProviderFilterUpdate}
          />
        </Flex>

        <Box sx={{px: ['12px']}}>
          <Grid gap={0} columns={columns} sx={{color: 'text.t100'}}>
            <Text variant="caption" color="text.t100">
              เบอร์
            </Text>
            <Text variant="caption" color="text.t100" sx={{textAlign: 'center'}}>
              ผลรวม
            </Text>
            <Text variant="caption" color="text.t100" sx={{textAlign: 'right'}}>
              ราคา
            </Text>
            <Text variant="caption" color="text.t100" sx={{textAlign: 'right'}}>
              เครือข่าย
            </Text>
          </Grid>

          {bers && (
            <InfiniteScroll
              dataLength={bers.edges.length} //This is important field to render the next data
              next={next}
              hasMore={bers.pageInfo.hasNextPage}
              loader={<ListLoader />}
              endMessage={<Text></Text>}
            >
              <Grid gap={0} columns={[`1fr`]}>
                {bers.edges.map((edge, i) => (
                  <BerRow key={i} ber={edge.node} location={location} />
                ))}
              </Grid>
            </InfiniteScroll>
          )}
        </Box>
      </Container>
    </>
  );
}
