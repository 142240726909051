import {gql} from '@apollo/client';
import {
  BatchUpsertResult,
  Ber,
  berFragment,
  berOffsetConnectionFragment,
  BerOrder,
  BerWhereInput,
  Connection,
  DeleteBer,
  EvaluationResult,
  Shop,
  UpsertBerInput,
} from './types';

export type ListBersResult = {
  bers: Connection<Ber>;
};

export type ListBersVars = {
  page?: number;
  itemPerPage?: number;
  orderBy?: BerOrder;
  where?: BerWhereInput;
};

export const LIST_BERS = gql`
  query bers($page: Int, $itemsPerPage: Int, $orderBy: BerOrder, $where: BerWhereInput) {
    bers: bers(page: $page, itemsPerPage: $itemsPerPage, orderBy: $orderBy, where: $where) {
      ...berOffsetConnectionFields
    }
  }
  ${berOffsetConnectionFragment}
`;

export const LIST_SIMILAR_BERS = gql`
  query similarBers($page: Int, $itemsPerPage: Int, $orderBy: BerOrder, $where: BerWhereInput) {
    bers: similarBers(page: $page, itemsPerPage: $itemsPerPage, orderBy: $orderBy, where: $where) {
      ...berOffsetConnectionFields
    }
  }
  ${berOffsetConnectionFragment}
`;

export type GetSellerResult = {
  bers: Connection<Ber>;
  shop: Shop;
};

export type GetSellerVars = {
  page?: number;
  itemPerPage?: number;
  orderBy?: BerOrder;
  where?: BerWhereInput;
};

export const GET_SELLER = gql`
  query seller {
    shop: sellerShop {
      id
      name
      lineId
      tel
      lastLoginAt
      pictureUrl
      totalBerCount
    }
  }
`;

export type GetBerResult = {
  ber: Ber;
};

export type GetBerVars = {
  id: number;
};

export const GET_BER = gql`
  query ber($id: ID!) {
    ber: ber(id: $id) {
      ...berFields
      evaluations {
        pair
        weight
        description
        pros
      }
      shop {
        id
        name
        lineId
        tel
        lastLoginAt
      }
    }
  }
  ${berFragment}
`;

export type EvaluateBerResult = {
  result: EvaluationResult;
};

export type EvaluateBerVars = {
  number: string;
};

export const EVALUATE_BER = gql`
  query evaluateBer($number: String!) {
    result: evaluateBer(number: $number) {
      number
      grade
      evaluations {
        pair
        weight
        description
        pros
      }
    }
  }
`;

export type BatchUpsertBerResult = {
  result: BatchUpsertResult;
};

export type BatchUpsertBerVars = {
  input: UpsertBerInput[];
  batchId: number;
  deleteOther: boolean;
};

export const BATCH_UPSERT_BER = gql`
  mutation batchUpsertBer($input: [UpsertBer!], $batchId: Int!, $deleteOther: Boolean!) {
    result: batchUpsertBer(input: $input, batchId: $batchId, deleteOther: $deleteOther) {
      upserted
      deleted
    }
  }
`;

export type UpsertBerResult = {
  ber: Ber;
};

export type UpsertBerVars = {
  input: UpsertBerInput;
};

export const UPSERT_BER = gql`
  mutation upsertBer($input: UpsertBer!) {
    ber: upsertBer(input: $input) {
      id
      price
      provider
    }
  }
`;

export type DeleteBerResult = {
  result: number;
};

export type DeleteBerVars = {
  input: DeleteBer;
};

export const DELETE_BER = gql`
  mutation deleteBer($input: DeleteBer!) {
    result: deleteBer(input: $input)
  }
`;
