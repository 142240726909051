import {gql} from '@apollo/client';

export const berFragment = gql`
  fragment berFields on Ber {
    id
    createdAt
    updatedAt
    number
    sum
    provider
    status
    price
    grade
    picture
    isPalindrome
    isTriple
    isQuadruple
    isQuintuple
    isDragon
    score
    shop {
      id
    }
  }
`;

export const berOffsetConnectionFragment = gql`
  ${berFragment}

  fragment berOffsetConnectionFields on BerOffsetConnection {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      totalPage
      nextPage
    }
    edges {
      cursor
      node {
        ...berFields
      }
    }
  }
`;

export const shopFragment = gql`
  fragment shopFields on Shop {
    id
    name
    lineId
    tel
    lastLoginAt
    pictureUrl
    totalBerCount
  }
`;

export const shopOffsetConnectionFragment = gql`
  ${shopFragment}

  fragment shopOffsetConnectionFields on ShopOffsetConnection {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      totalPage
      nextPage
    }
    edges {
      cursor
      node {
        ...shopFields
      }
    }
  }
`;

export type PageInfo = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  totalPage: number;
  nextPage: number;
};

export type Edge<T> = {
  cursor: string;
  node: T;
};

export type Connection<T> = {
  totalCount: number;
  pageInfo: PageInfo;
  edges: Edge<T>[];
};

export enum BerOrderField {
  PRICE = 'PRICE',
  SCORE = 'SCORE',
}

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type BerOrder = {
  field: BerOrderField;
  direction: OrderDirection;
};

export enum BerProvider {
  AIS = 'AIS',
  DTAC = 'DTAC',
  TRUE = 'TRUE',
  PENGUIN = 'PENGUIN',
  TOT = 'TOT',
  CAT = 'CAT',
  MYBYCAT = 'MYBYCAT',
  IEC = 'IEC',
  IMOBILE = 'IMOBILE',
}

export enum BerStatus {
  AVAILABLE = 'AVAILABLE',
}

export enum BerGrade {
  AA = 'AA',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
}

export enum UserRole {
  USER = 'USER',
  SELLER = 'SELLER',
  ADIN = 'ADMIN',
}

export type BerWhereInput = {
  priceGTE?: number;
  priceLT?: number;
  isPalindrome?: boolean;
  isTriple?: boolean;
  isQuadruple?: boolean;
  isQuintuple?: boolean;
  isDoubleGroup?: boolean;
  isDragon?: boolean;
  providers?: BerProvider[];
  grades?: BerGrade[];
  sums?: number[];
  digit2?: string;
  digit3?: string;
  digit4?: string;
  digit5?: string;
  digit6?: string;
  digit7?: string;
  digit8?: string;
  digit9?: string;
  digit10?: string;
  contains?: string[];
  notContains?: string[];
  tags?: string[];
  swapPair?: boolean;
  shopId?: number;
};

export type Node = {
  id: number;
};

export type Ber = Node & {
  cid: string;
  createdAt: string;
  updatedAt: string;
  number: string;
  sum: number;
  provider: BerProvider;
  status: BerStatus;
  price: number;
  grade: BerGrade;
  picture: string;
  isPalindrome: boolean;
  isTriple: boolean;
  isQuadruple: boolean;
  isQuintuple: boolean;
  isDragon: boolean;
  score: number;
  evaluations?: Evaluation[];
  shop: Shop;
};

export type Evaluation = {
  pair: string;
  weight: number;
  description: string;
  pros: string;
  cons: string;
};

export type Shop = Node & {
  name: string;
  lineId: string;
  tel: string;
  lastLoginAt: string;
  pictureUrl: string;
  totalBerCount: number;
};

export enum ShopOrderField {
  LAST_LOGIN_AT = 'LAST_LOGIN_AT',
}

export type ShopOrder = {
  field: ShopOrderField;
  direction: OrderDirection;
};

export type EvaluationResult = {
  number: string;
  grade: BerGrade;
  evaluations: Evaluation[];
};

export type BatchUpsertResult = {
  upserted: number;
  deleted: number;
};

export type UpsertBerInput = {
  number: string;
  price: number;
  provider: BerProvider;
};

export type DeleteBer = {
  id: number;
};
