import {configureStore} from '@reduxjs/toolkit';
import {load, save} from 'redux-localstorage-simple';
import application from './application/reducer';
import bers from './bers/reducer';
import user from './user/reducer';

const PERSISTED_KEYS: string[] = ['user'];

const namespace = 'berhit';

const store = configureStore({
  reducer: {application, user, bers},
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({thunk: false}).concat(save({namespace, states: PERSISTED_KEYS, disableWarnings: true})),
  preloadedState: load({namespace, states: PERSISTED_KEYS, disableWarnings: true}),
});

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
