import Portal from '@reach/portal';
import {Position, TooltipPopup, useTooltip} from '@reach/tooltip';
import '@reach/tooltip/styles.css';
import React, {cloneElement, CSSProperties, FC, useMemo} from 'react';

// Center the tooltip, but collisions will win
const topCentered: Position = (triggerRect, tooltipRect) => {
  if (!triggerRect || !tooltipRect) return {};

  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;
  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.top - 32 - window.scrollY,
  };
};

const bottomCentered: Position = (triggerRect, tooltipRect) => {
  if (!triggerRect || !tooltipRect) return {};

  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;
  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.top + 42 - window.scrollY,
  };
};

const leftCentered: Position = (triggerRect, tooltipRect) => {
  if (!triggerRect || !tooltipRect) return {};

  return {
    left: triggerRect?.left - tooltipRect?.width - 8,
    top: triggerRect.top + 3 - window.scrollY,
  };
};

const rightCentered: Position = (triggerRect, _tooltipRect) => {
  if (!triggerRect) return {};

  return {
    left: triggerRect?.left + 36,
    top: triggerRect.top + 3 - window.scrollY,
  };
};

const style: CSSProperties = {
  background: 'hsla(0, 0%, 0%, 0.75)',
  fontWeight: 300,
  fontSize: '12px',
  lineHeight: '19px',
  letterSpacing: '0.02em',
  color: '#FFFFFF',
  border: 'none',
  borderRadius: '4px',
  padding: '0.5em 1em',
};

export interface TriangleTooltipProps {
  children: JSX.Element;
  label: string;
  'aria-label': string;
  position?: 'top' | 'bottom' | 'left' | 'right';
}

export const TriangleTooltip: FC<TriangleTooltipProps> = ({
  children,
  label,
  'aria-label': ariaLabel,
  position = 'top',
}) => {
  // get the props from useTooltip
  const [trigger, tooltip] = useTooltip();
  // destructure off what we need to position the triangle
  const {isVisible, triggerRect} = tooltip;

  const pos = useMemo((): {
    top: number | undefined;
    left: number | undefined;
  } => {
    switch (position) {
      case 'top':
        return {
          top: (triggerRect && triggerRect.top - 8) || undefined,
          left: (triggerRect && triggerRect.left - 10 + triggerRect.width / 2) || undefined,
        };
      case 'bottom':
        return {
          top: (triggerRect && triggerRect.top + 37) || undefined,
          left: (triggerRect && triggerRect.left - 10 + triggerRect.width / 2) || undefined,
        };
      case 'left':
        return {
          top: (triggerRect && triggerRect.top + 10) || undefined,
          left: (triggerRect && triggerRect.left - 25 + triggerRect.width / 2) || undefined,
        };
      case 'right':
        return {
          top: (triggerRect && triggerRect.top + 10) || undefined,
          left: (triggerRect && triggerRect.left + triggerRect.width) || undefined,
        };

      default:
        return {
          top: (triggerRect && triggerRect.top - 12) || undefined,
          left: (triggerRect && triggerRect.left - 10 + triggerRect.width / 2) || undefined,
        };
    }
  }, [position, triggerRect]);

  const border = () => {
    switch (position) {
      case 'top':
        return {
          borderLeft: '5px solid transparent',
          borderRight: '5px solid transparent',
          borderTop: '5px solid black',
        };
      case 'bottom':
        return {
          borderLeft: '5px solid transparent',
          borderRight: '5px solid transparent',
          borderBottom: '5px solid black',
        };
      case 'left':
        return {
          borderTop: '5px solid transparent',
          borderBottom: '5px solid transparent',
          borderLeft: '5px solid black',
        };
      case 'right':
        return {
          borderTop: '5px solid transparent',
          borderBottom: '5px solid transparent',
          borderRight: '5px solid black',
        };

      default:
        return {
          borderLeft: '5px solid transparent',
          borderRight: '5px solid transparent',
          borderTop: '5px solid black',
        };
    }
  };

  let positionFn = topCentered;
  if (position === 'bottom') {
    positionFn = bottomCentered;
  } else if (position === 'left') {
    positionFn = leftCentered;
  } else if (position === 'right') {
    positionFn = rightCentered;
  }

  return (
    <>
      {cloneElement(children, trigger)}
      {isVisible && (
        // The Triangle. We position it relative to the trigger, not the popup
        // so that collisions don't have a triangle pointing off to nowhere.
        // Using a Portal may seem a little extreme, but we can keep the
        // positioning logic simpler here instead of needing to consider
        // the popup's position relative to the trigger and collisions
        <Portal>
          <div
            style={{
              position: 'absolute',
              ...pos,
              width: 0,
              height: 0,
              ...border(),
              zIndex: 2000,
            }}
          />
        </Portal>
      )}
      <TooltipPopup {...tooltip} label={label} aria-label={ariaLabel} style={style} position={positionFn} />
    </>
  );
};
