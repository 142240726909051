import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {Box} from 'theme-ui';

export const ListLoader = () => {
  return (
    <Box>
      <Skeleton count={3} width="100%" />
    </Box>
  );
};
