import React from 'react';
import {Link} from 'react-router-dom';
import {Box, Card, Flex, Text} from 'theme-ui';
import {STATIC_ASSET_PREFIX} from '../../constant';
import {Shop} from '../../graph/types';

export const ShopInfo = ({shop}: {shop?: Shop}) => {
  const pictureUrl = `${STATIC_ASSET_PREFIX ?? ''}/${shop?.pictureUrl}`;
  return (
    <Link to={{pathname: `/shops/${shop?.id}`}}>
      <Card variant="shopInfo" sx={{cursor: 'pointer'}}>
        <Flex sx={{flexDirection: 'column'}}>
          <Box
            sx={{
              width: '167px',
              height: '100px',
              backgroundImage: `url(${pictureUrl})`,
              backgroundSize: '100% auto',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              borderRadius: '8px 8px 0 0',
            }}
          ></Box>
          <Text variant="header" sx={{px: '8px', mt: '8px'}}>
            {shop?.name.slice(0, 20)}
          </Text>
          <Text variant="caption" color="text.t100" sx={{px: '8px'}}>
            {shop?.totalBerCount} เบอร์
          </Text>
        </Flex>
      </Card>
    </Link>
  );
};
