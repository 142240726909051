import {useQuery} from '@apollo/client';
import styled from '@emotion/styled';
import {DialogContent, DialogOverlay} from '@reach/dialog';
import '@reach/dialog/styles.css';
import React, {FC, useState} from 'react';
import {FiX} from 'react-icons/fi';
import {useHistory, useParams} from 'react-router-dom';
import {animated, useTransition} from 'react-spring';
import {Box} from 'theme-ui';
import {GetBerResult, GetBerVars, GET_BER} from '../../graph/ber';
import {Ber} from '../../graph/types';
import {BerFull} from './BerFull';

export const StyledDialogOverlay = styled(DialogOverlay)`
  /* background: hsla(1, 100%, 100%, 0.9); */
  /* overflow-y: scroll; */
`;

export const StyledDialogContent = styled(DialogContent)`
  width: 90vw;
  max-width: 350px;
  min-height: 100vh;
  padding: 24px;
  border-radius: 6px;
  /* margin-top: 180px; */
  /* &[data-reach-dialog-content] {
    margin: 40px auto;
  } */
`;

const AnimatedDialogOverlay = animated(StyledDialogOverlay);
const AnimatedDialogContent = animated(StyledDialogContent);

export type BerModalProps = {
  ber?: Ber;
};

export const BerModal: FC<BerModalProps> = ({ber}): JSX.Element => {
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(true);
  const {id} = useParams<{id: string}>();

  const {data: berResult} = useQuery<GetBerResult, GetBerVars>(GET_BER, {
    variables: {id: parseInt(id)},
  });

  const onDismiss = () => {
    setShowDialog(false);
    history.goBack();
  };

  const transitions = useTransition(showDialog, {
    from: {opacity: 0, y: -10},
    enter: {opacity: 1, y: 0},
    leave: {opacity: 0, y: 10},
  });

  return (
    <Box>
      {transitions(
        (styles, item) =>
          item && (
            <AnimatedDialogOverlay isOpen={showDialog} onDismiss={onDismiss} style={{opacity: styles.opacity}}>
              <AnimatedDialogContent
                aria-label={`number ${ber?.number}`}
                style={{
                  transform: styles.y.to((value) => `translate3d(0px, ${value}px, 0px)`),
                  borderRadius: 10,
                }}
              >
                <Box sx={{position: 'relative'}}>
                  <Box onClick={onDismiss} sx={{position: 'absolute', right: '0', cursor: 'pointer'}}>
                    <FiX size="22px" />
                  </Box>
                  {berResult && <BerFull ber={berResult.ber} onDismiss={onDismiss} />}
                </Box>
              </AnimatedDialogContent>
            </AnimatedDialogOverlay>
          )
      )}
    </Box>
  );
};
