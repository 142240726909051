import {useQuery} from '@apollo/client';
import styled from '@emotion/styled';
import {DialogContent, DialogOverlay} from '@reach/dialog';
import '@reach/dialog/styles.css';
import React, {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {EvaluateBerResult, EvaluateBerVars, EVALUATE_BER} from '../../graph/ber';
import {BerEvaluation} from '../Ber/BerEvaluation';

export const StyledDialogOverlay = styled(DialogOverlay)`
  /* background: hsla(1, 100%, 100%, 0.9); */
`;

export const StyledDialogContent = styled(DialogContent)`
  width: 90vw;
  max-width: 350px;
  min-height: 50vh;
  padding: 24px;
  border-radius: 6px;
`;

export const EvaluationModal = () => {
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(true);
  const {ber} = useParams<{ber: string}>();

  const {data: berResult} = useQuery<EvaluateBerResult, EvaluateBerVars>(EVALUATE_BER, {
    variables: {
      number: ber,
    },
  });

  const onDismiss = () => {
    setShowDialog(false);
    history.goBack();
  };

  return (
    <StyledDialogOverlay isOpen={showDialog} onDismiss={onDismiss}>
      <StyledDialogContent aria-label="get ber prediction">
        <BerEvaluation result={berResult?.result} />
      </StyledDialogContent>
    </StyledDialogOverlay>
  );
};
