import {css, Global} from '@emotion/react';
import React, {ReactNode} from 'react';
import {Theme, ThemeProvider as ThemeUIThemeProvider} from 'theme-ui';

export const theme: Theme = {
  initialColorModeName: 'light',
  // breakpoints: ['479px', '767px', '991px', '@media (min-width: 991px)'],
  breakpoints: ['479px', '767px', '@media (min-width: 767px)'],
  colors: {
    background: '#ffffff',
    text: {
      t0: '#ffffff',
      t100: '#a1a1a1',
      t1000: '#414141',
    },
    neutral: {
      n0: '#ffffff',
      n10: '#ececec',
      n100: '#c8c8c8',
      n1000: '#414141',
    },
    accent: {
      r1000: '#b61f2f',
      b1000: '#3665DD',
    },
  },
  space: [0, 4, 8, 12, 16, 20, 24, 32, 64, 128, 256, 512],
  fonts: {
    body: 'Mitr, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 18],
  fontWeights: {
    body: 400,
    heading: 700,
    display: 900,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  text: {
    default: {
      fontWeight: 300,
      fontSize: [1],
      letterSpacing: 0,
      lineHeight: '22px',
      color: 'text.t1000',
    },
    brandText: {
      fontSize: [2],
      lineHeight: '18px',
      color: 'text.t1000',
    },
    brandSubtext: {
      fontSize: '10px',
      lineHeight: '12px',
      color: 'text.t1000',
      fontWeight: 300,
    },
    title: {
      name: 'Mobile/Title',
      fontWeight: 400,
      fontSize: [3],
      letterSpacing: 0,
      lineHeight: '22px',
      color: 'text.t1000',
    },
    digit: {
      name: 'Mobile/Digit',
      fontWeight: 400,
      fontSize: [2],
      letterSpacing: '0.03em',
      lineHeight: '18px',
    },
    header: {
      name: 'Mobile/Header',
      fontWeight: 400,
      fontSize: [1],
      letterSpacing: 0,
      lineHeight: '18px',
      color: 'text.t1000',
    },
    body: {
      name: 'Mobile/Body',
      fontWeight: 300,
      fontSize: [1],
      letterSpacing: 0,
      lineHeight: '22px',
    },
    caption: {
      name: 'Mobile/Caption',
      fontFamily: 'mitr',
      fontWeight: 300,
      fontSize: [0],
      letterSpacing: '0.02em',
      lineHeight: '19px',
      color: 'text.t1000',
    },
    digitSeparator: {
      mr: ['8px'],
      color: '#c6c6c6',
      fontSize: ['18px', '21px'],
      lineHeight: '0px',
      fontWseight: '400',
    },
    disclaimer: {
      fontFamily: 'mitr',
      fontWeight: 300,
      fontSize: '9px',
      color: 'text.t100',
    },
    callout: {
      bg: 'accent.r1000',
      color: 'text.t0',
      fontSize: '14px',
      lineHeight: '18px',
      py: '4px',
      px: '8px',
      borderRadius: '20px',
    },
  },
  layout: {
    container: {
      maxWidth: '767px',
    },
    navbar: {
      maxWidth: '992px',
    },
    fullCard: {
      px: [4],
    },
  },
  forms: {
    digitBox: {
      width: ['26px', '30px'],
      height: ['30px', '33px'],
      mr: ['4px'],
      mt: ['8px'],
      p: 0,
      border: '1px solid #c6c6c6',
      borderRadius: ['6px'],
      variant: 'text.digit',
      textAlign: 'center',
      '&:focus': {
        borderColor: '#c6c6c6',
      },
    },
    label: {
      variant: 'text.caption',
      // fontSize: '14px',
      // lineHeight: '20px',
      // // fontWeight: 700,
      // color: 'textLight',
    },
    input: {
      variant: 'text.body',
      bg: 'neutral.n0',
      border: '1px solid #C8C8C8',
      borderRadius: '4px',
      '&:disabled': {
        bg: 'neutral.n10',
      },
    },
    select: {
      variant: 'text.body',
      bg: 'neutral.n0',
      border: '1px solid #C8C8C8',
      borderRadius: '4px',
      '&:disabled': {
        bg: 'neutral.n10',
      },
    },
    submit: {
      variant: 'buttons.primary',
    },
    checkbox: {},
    uploadLabel: {
      variant: 'text.caption',
      color: 'accent.b1000',
      display: 'inline-block',
      cursor: 'pointer',
    },
    labelCaption: {
      variant: 'text.caption',
    },
  },
  buttons: {
    primary: {
      color: 'text.t0',
      bg: 'accent.r1000',
      borderRadius: '6px',
      cursor: 'pointer',
      px: '16px',
      py: '8px',
      '&:disabled': {
        cursor: 'not-allowed',
        bg: 'neutral.n10',
        border: '1px solid',
        borderColor: 'neutral.n100',
      },
    },
    primaryReverse: {
      variant: 'buttons.primary',
      color: 'accent.r1000',
      bg: 'text.t0',
      border: '1px solid #B61F2F',
    },
    primaryHallow: {
      variant: 'text.header',
      color: 'text.t1000',
      bg: 'neutral.n0',
      borderRadius: '4px',
      cursor: 'pointer',
      // px: '16px',
      // py: '8px',
      border: '1px solid #C8C8C8',
    },
    accent: {
      variant: 'buttons.primary',
      color: 'text.t0',
      bg: 'accent.b1000',
    },
    accentHallow: {
      variant: 'buttons.primary',
      color: 'accent.b1000',
      bg: 'neutral.n0',
      border: '1px solid #3665DD',
      '&:disabled': {
        cursor: 'not-allowed',
        color: 'text.t100',
        bg: 'neutral.n10',
        border: '1px solid',
        borderColor: 'neutral.n100',
      },
    },
    tag: {
      variant: 'text.caption',
      color: 'text.t1000',
      bg: 'neutral.n10',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
      borderRadius: '6px',
      px: '8px',
      py: '4px',
    },
    tagReverse: {
      variant: 'buttons.tag',
      color: 'text.t0',
      bg: 'accent.r1000',
    },
    text: {
      // px: ['8px'],
      // py: ['4px'],
      p: 0,
      m: 0,
      color: 'text.t1000',
      bg: 'neutral.n0',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      variant: 'text.caption',
      cursor: 'pointer',
    },
    menu: {
      variant: 'buttons.text',
      px: ['8px'],
      py: ['4px'],
    },
  },
  cards: {
    grade: {
      backgroundImage: 'url(/images/grade_bg@1x.png)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      width: '60px',
      height: '66px',
    },
    shopInfo: {
      background: ' #FFFFFF',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: ' 8px',
      width: ['167px'],
      height: ['157px'],
      mb: ['16px'],
    },
  },
  images: {
    shop: {
      borderRadius: '8px',
      bg: 'neutral.n10',
      width: '93px',
      height: '93px',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      bg: 'neutral.n0',
      color: 'text.t1000',
    },
    h1: {
      variant: 'text.display',
    },
    h2: {
      variant: 'text.heading',
      fontSize: 5,
    },
    h3: {
      variant: 'text.heading',
      fontSize: 4,
    },
    h4: {
      variant: 'text.heading',
      fontSize: 3,
    },
    h5: {
      variant: 'text.heading',
      fontSize: 2,
    },
    h6: {
      variant: 'text.heading',
      fontSize: 1,
    },
    a: {
      color: 'primary',
      '&:hover': {
        color: 'secondary',
      },
    },
    pre: {
      variant: 'prism',
      fontFamily: 'monospace',
      fontSize: 1,
      p: 3,
      color: 'text',
      bg: 'muted',
      overflow: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      color: 'secondary',
      fontSize: 1,
    },
    inlineCode: {
      fontFamily: 'monospace',
      color: 'secondary',
      bg: 'muted',
    },
    table: {
      width: '100%',
      my: 4,
      // borderCollapse: 'separate',
      // borderSpacing: 0,
      'th,td': {
        // textAlign: 'left',
        py: '4px',
        pr: '4px',
        pl: 0,
        borderColor: 'muted',
        borderBottomStyle: 'solid',
      },
    },
    th: {
      verticalAlign: 'bottom',
      borderBottomWidth: '2px',
    },
    td: {
      verticalAlign: 'top',
      borderBottomWidth: '1px',
    },
    hr: {
      border: 0,
      borderBottom: '1px solid',
      borderColor: 'neutral.n100',
      my: '12px',
    },
    img: {
      maxWidth: '100%',
    },
  },
};

export const ThemeProvider = ({children}: {children?: ReactNode}) => {
  return <ThemeUIThemeProvider theme={theme}>{children}</ThemeUIThemeProvider>;
};

export const GlobalStyle = (): JSX.Element => (
  <Global
    styles={css`
      *,
      *::before,
      *::after {
        margin: 0;
        padding: 0;
        border: 0;
        border-collapse: collapse;
        border-spacing: 0;
        box-sizing: border-box;
        outline: none;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        list-style: none;
        quotes: none;
      }

      a {
        text-decoration: none;
        color: unset;
      }
      a:active,
      a:hover,
      a:visited {
        outline: 0;
      }
    `}
  />
);

export const ThemeGlobalStyle = (): JSX.Element => {
  return (
    <Global
      styles={css`
        body {
          /* min-height: 100vh; */
        }
      `}
    />
  );
};
