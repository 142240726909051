import {useQuery} from '@apollo/client';
import React, {useCallback, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import {Box, Container, Flex, Grid, Text} from 'theme-ui';
import {ListLoader} from '../../components/Loading/BerListLoader';
import {ShopInfo} from '../../components/Shop/ShopInfo';
import {ListShopsResult, ListShopsVars, LIST_SHOPS} from '../../graph/shop';
import {Connection, Shop} from '../../graph/types';

export default function Shops() {
  const [shops, setShops] = useState<Connection<Shop> | undefined>(undefined);
  const [variables, setVariable] = useState<ListShopsVars>({});

  useQuery<ListShopsResult, ListShopsVars>(LIST_SHOPS, {
    variables,
    onCompleted: (data) => {
      if (!data) return;

      if (!shops) {
        setShops(data.shops);
      } else {
        setShops({
          edges: [...shops.edges, ...data.shops.edges],
          pageInfo: data.shops.pageInfo,
          totalCount: data.shops.totalCount,
        });
      }
    },
  });

  const next = useCallback(() => {
    setVariable({...variables, page: shops?.pageInfo.nextPage});
  }, [shops, variables]);

  return (
    <Container>
      <Flex sx={{flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline', p: ['16px']}}>
        {shops?.totalCount ? (
          <Text variant="header">มีทั้งหมด {shops.totalCount.toLocaleString()} ร้าน</Text>
        ) : (
          <Skeleton width="100px" />
        )}
      </Flex>

      <Box sx={{px: ['12px']}}>
        {shops && (
          <InfiniteScroll
            dataLength={shops.edges.length}
            next={next}
            hasMore={shops.pageInfo.hasNextPage}
            loader={<ListLoader />}
            endMessage={<Text></Text>}
          >
            <Grid gap={0} columns={[`repeat(auto-fit, minmax(167px, 1fr))`]}>
              {shops.edges.map((edge, i) => (
                <ShopInfo shop={edge.node} key={i} />
              ))}
            </Grid>
          </InfiniteScroll>
        )}
      </Box>
    </Container>
  );
}
