import {useMutation} from '@apollo/client';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Box, Container, Flex, Input, Label, Text} from 'theme-ui';
import {LoginEmailResult, LoginEmailVars, LOGIN_EMAIL} from '../../graph/auth';
import {AppDispatch} from '../../state';
import {updateUserSession} from '../../state/user/actions';

export default function Login() {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [fetchData] = useMutation<LoginEmailResult, LoginEmailVars>(LOGIN_EMAIL, {
    variables: {
      email,
      password,
    },
    onCompleted: (data) => {
      if (!data) return;

      dispatch(updateUserSession({session: data.session}));

      history.push('/seller');

      // const callback = router.query.callback;
      // console.log('callback', router.query, typeof callback);

      // if (typeof callback === 'string') {
      //   router.push(callback as string, callback as string);
      // } else {
      //   router.push('/', '/');
      // }
    },
    onError: (error) => {
      toast(error.message, {type: 'error'});
    },
  });

  const submit = (e: any) => {
    e.preventDefault();

    fetchData();
  };

  return (
    <Box sx={{bg: 'neutral.n10', height: '100vh'}}>
      <Container>
        <Flex sx={{justifyContent: 'center', alignItem: 'center'}}>
          <Box
            as="form"
            onSubmit={submit}
            sx={{width: ['303px'], bg: 'neutral.n0', my: '36px', p: '16px', borderRadius: '8px'}}
          >
            <Text variant="title">เข้าสู่ระบบผู้ขาย</Text>

            <Label htmlFor="email" sx={{mt: '16px'}}>
              อีเมล
            </Label>
            <Input name="email" id="email" sx={{mt: '8px'}} required onChange={(e) => setEmail(e.target.value)} />

            <Label htmlFor="password" sx={{mt: '16px'}}>
              รหัสผ่าน
            </Label>
            <Input
              type="password"
              name="password"
              id="password"
              required
              sx={{mt: '8px'}}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Box>
              <Text variant="caption">ลืมรหัสผ่าน?</Text>{' '}
              <Link to="/forgetpassword">
                <Text variant="caption" color="accent.b1000" sx={{cursor: 'pointer'}}>
                  รีเซ็ทรหัสผ่าน
                </Text>
              </Link>
            </Box>

            <Input variant="submit" type="submit" value="เข้าสู่ระบบ" sx={{mt: '16px', bg: 'accent.b1000'}} />
            <Box sx={{mt: '16px'}}>
              <Text variant="caption">ยังไม่มีบัญชี?</Text>{' '}
              <Link to="/signup">
                <Text variant="caption" color="accent.b1000" sx={{cursor: 'pointer'}}>
                  สมัครบัญชี
                </Text>
              </Link>
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}
