import styled from '@emotion/styled';
import {DialogContent, DialogOverlay} from '@reach/dialog';
import '@reach/dialog/styles.css';
import React, {FC, ReactNode} from 'react';
import {FiX} from 'react-icons/fi';
import {animated, useTransition} from 'react-spring';
import {Box} from 'theme-ui';

export const StyledDialogOverlay = styled(DialogOverlay)`
  /* background: hsla(1, 100%, 100%, 0.9); */
  /* overflow-y: scroll; */
`;

export const StyledDialogContent = styled(DialogContent)`
  width: 90vw;
  max-width: 350px;
  padding: 24px;
  border-radius: 6px;
  /* margin-top: 180px; */
  /* &[data-reach-dialog-content] {
    margin: 40px auto;
  } */
`;

const AnimatedDialogOverlay = animated(StyledDialogOverlay);
const AnimatedDialogContent = animated(StyledDialogContent);

export type ModalProps = {
  children: ReactNode;
  showDialog: boolean;
  onDismiss: () => void;
};

export const Modal: FC<ModalProps> = ({children, showDialog, onDismiss}): JSX.Element => {
  // const [showDialog, setShowDialog] = useState(true);

  // const onDismiss = () => {
  //   setShowDialog(false);
  // };

  const transitions = useTransition(showDialog, {
    from: {opacity: 0, y: -10},
    enter: {opacity: 1, y: 0},
    leave: {opacity: 0, y: 10},
  });

  return (
    <Box>
      {transitions(
        (styles, item) =>
          item && (
            <AnimatedDialogOverlay isOpen={showDialog} onDismiss={onDismiss} style={{opacity: styles.opacity}}>
              <AnimatedDialogContent
                aria-label="modal"
                style={{
                  transform: styles.y.to((value) => `translate3d(0px, ${value}px, 0px)`),
                  borderRadius: 10,
                }}
              >
                <Box sx={{position: 'relative'}}>
                  <Box onClick={onDismiss} sx={{position: 'absolute', right: '0', cursor: 'pointer'}}>
                    <FiX size="22px" />
                  </Box>
                  {children}
                </Box>
              </AnimatedDialogContent>
            </AnimatedDialogOverlay>
          )
      )}
    </Box>
  );
};
