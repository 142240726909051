import {createAction} from '@reduxjs/toolkit';
import {ListBersResult} from '../../graph/ber';

export const addListBersQueryResult =
  createAction<{page: string; result: ListBersResult}>('bers/addListBersQueryResult');

export const resetListBersQueryResult = createAction<{page: string}>('bers/resetListBersQueryResult');

export const deleteBer = createAction<{page: string; id: number}>('bers/deletBer');

export const updateBer = createAction<{page: string; id: number; price: number; provider: string}>('bers/updateBer');
