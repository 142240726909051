import {gql} from '@apollo/client';
import {Connection, Shop, shopOffsetConnectionFragment, ShopOrder} from './types';

export type ListShopsResult = {
  shops: Connection<Shop>;
};

export type ListShopsVars = {
  page?: number;
  itemPerPage?: number;
  orderBy?: ShopOrder;
};

export const LIST_SHOPS = gql`
  query shops($page: Int, $itemsPerPage: Int, $orderBy: ShopOrder) {
    shops: shops(page: $page, itemsPerPage: $itemsPerPage, orderBy: $orderBy) {
      ...shopOffsetConnectionFields
    }
  }
  ${shopOffsetConnectionFragment}
`;

export type GetShopResult = {
  shop: Shop;
};

export type GetShopVars = {
  id: number;
};

export const GET_SHOP = gql`
  query shop($id: ID!) {
    shop: shop(id: $id) {
      id
      name
      lineId
      tel
      lastLoginAt
      pictureUrl
      totalBerCount
    }
  }
`;

export type UpdateShopResult = {
  shop: Shop;
};

export type UpdateShopVars = {
  name: string;
  lineId: string;
  tel: string;
};

export const UPDATE_SHOP = gql`
  mutation updateShop($name: String, $lineId: String, $tel: String) {
    shop: updateShop(input: {name: $name, lineId: $lineId, tel: $tel}) {
      id
      name
      lineId
      tel
      lastLoginAt
      pictureUrl
      totalBerCount
    }
  }
`;

export type UploadShopPhotoResult = {
  shop: Shop;
};

export type UploadShopPhotoVars = {
  file: File;
};

export const UPLOAD_SHOP_PHOTO = gql`
  mutation uploadShopPhoto($file: Upload!) {
    shop: uploadShopPhoto(file: $file) {
      id
      name
      lineId
      tel
      lastLoginAt
      pictureUrl
      totalBerCount
    }
  }
`;
