export const STATIC_ASSET_PREFIX = process.env.REACT_APP_STATIC_ASSET_PREFIX || '';

export const NAVBAR_HEIGH = '60px';
export const ASPECT_RATIO = 16 / 9;

export const PAGE_BERS_INDEX = 'bers_index';
export const PAGE_SIMILAR_BERS_INDEX = 'similar_bers_index';

export const TAGS = [
  {
    text: 'กล้าตัดสินใจ',
    id: 'tag1',
  },
  {
    text: 'กล้าได้กล้าเสีย',
    id: 'tag2',
  },
  {
    text: 'การเงินดี',
    id: 'tag3',
  },
  {
    text: 'คำพูดน่าเชื่อถือ',
    id: 'tag4',
  },
  {
    text: 'คิดเร็วทำเร็ว',
    id: 'tag5',
  },
  {
    text: 'คู่ครองดี',
    id: 'tag6',
  },
  {
    text: 'ค้าขายเก่ง',
    id: 'tag7',
  },
  {
    text: 'งานมั่นคง',
    id: 'tag8',
  },
  {
    text: 'จิตใจมั่นคง',
    id: 'tag9',
  },
  {
    text: 'ฉลาด',
    id: 'tag10',
  },
  {
    text: 'ชอบถ่ายทอดความรู้',
    id: 'tag11',
  },
  {
    text: 'ชอบลงทุน',
    id: 'tag12',
  },
  {
    text: 'ชื่อเสียงโด่งดัง',
    id: 'tag13',
  },
  {
    text: 'ช่างพูดช่างคุย',
    id: 'tag14',
  },
  {
    text: 'ดวงแต่งงาน',
    id: 'tag15',
  },
  {
    text: 'ดูภูมิฐาน',
    id: 'tag16',
  },
  {
    text: 'ติดต่อประสานงาน',
    id: 'tag17',
  },
  {
    text: 'ทันข่าวสาร',
    id: 'tag18',
  },
  {
    text: 'ทันคน',
    id: 'tag19',
  },
  {
    text: 'ทำบุญขึ้น',
    id: 'tag20',
  },
  {
    text: 'ทำอาหารเก่ง',
    id: 'tag21',
  },
  {
    text: 'ธุรกิจต่างประเทศ',
    id: 'tag22',
  },
  {
    text: 'ธุรกิจระหว่างประเทศ',
    id: 'tag23',
  },
  {
    text: 'นารีอุปถัมภ์',
    id: 'tag24',
  },
  {
    text: 'น่ารัก',
    id: 'tag25',
  },
  {
    text: 'บริวารดี',
    id: 'tag26',
  },
  {
    text: 'ปากหวาน',
    id: 'tag27',
  },
  {
    text: 'ผู้ใหญ่อุปถัมภ์',
    id: 'tag28',
  },
  {
    text: 'พลิกแพลงเก่ง',
    id: 'tag29',
  },
  {
    text: 'มองโลกในแง่ดี',
    id: 'tag30',
  },
  {
    text: 'มั่นใจในตัวเอง',
    id: 'tag31',
  },
  {
    text: 'มาดนักเลง',
    id: 'tag32',
  },
  {
    text: 'มารยาทดี',
    id: 'tag33',
  },
  {
    text: 'มีลางสังหรณ์',
    id: 'tag34',
  },
  {
    text: 'มีศีลธรรม',
    id: 'tag35',
  },
  {
    text: 'มีหลักการ',
    id: 'tag36',
  },
  {
    text: 'มีเมตตา',
    id: 'tag37',
  },
  {
    text: 'มีเสน่ห์',
    id: 'tag38',
  },
  {
    text: 'มุมมองหลากหลาย',
    id: 'tag39',
  },
  {
    text: 'มุ่งมั่นจริงจัง',
    id: 'tag40',
  },
  {
    text: 'ยุติธรรม',
    id: 'tag41',
  },
  {
    text: 'รสนิยมดี',
    id: 'tag42',
  },
  {
    text: 'รอบรู้เทคโนโลยี',
    id: 'tag43',
  },
  {
    text: 'รักสวยรักงาม',
    id: 'tag44',
  },
  {
    text: 'ร่าเริงแจ่มใส',
    id: 'tag45',
  },
  {
    text: 'ละเอียดรอบคอบ',
    id: 'tag46',
  },
  {
    text: 'วางแผนเก่ง',
    id: 'tag47',
  },
  {
    text: 'วาสนาดี',
    id: 'tag48',
  },
  {
    text: 'วิสัยทัศน์กว้างไกล',
    id: 'tag49',
  },
  {
    text: 'ศิลปะ',
    id: 'tag50',
  },
  {
    text: 'ศิลปิน',
    id: 'tag51',
  },
  {
    text: 'ศึกษาธรรมะ',
    id: 'tag52',
  },
  {
    text: 'สติสมาธิดี',
    id: 'tag53',
  },
  {
    text: 'สนใจศาสตร์เล้นลับ',
    id: 'tag54',
  },
  {
    text: 'สร้างสรรค์',
    id: 'tag55',
  },
  {
    text: 'สิ่งศักดิ์สิทธิ์คุ้มครอง',
    id: 'tag56',
  },
  {
    text: 'สุขภาพดี',
    id: 'tag57',
  },
  {
    text: 'สุขุม',
    id: 'tag58',
  },
  {
    text: 'สู้งาน',
    id: 'tag59',
  },
  {
    text: 'อดทน',
    id: 'tag60',
  },
  {
    text: 'อบอุ่น',
    id: 'tag61',
  },
  {
    text: 'อ่อนโยน',
    id: 'tag62',
  },
  {
    text: 'เก่งวิเคราะห์คำนวน',
    id: 'tag63',
  },
  {
    text: 'เขียนเก่ง',
    id: 'tag64',
  },
  {
    text: 'เจรจาต่อรอง',
    id: 'tag65',
  },
  {
    text: 'เดินทาง',
    id: 'tag66',
  },
  {
    text: 'เป็นที่ปรึกษา',
    id: 'tag67',
  },
  {
    text: 'เป็นที่รักของผู้คน',
    id: 'tag68',
  },
  {
    text: 'เป็นผู้นำ',
    id: 'tag69',
  },
  {
    text: 'เป็นใหญ่ในการงาน',
    id: 'tag70',
  },
  {
    text: 'เพื่อนเยอะ',
    id: 'tag71',
  },
  {
    text: 'เรียนเก่ง',
    id: 'tag72',
  },
  {
    text: 'เล่าเรื่องเก่ง',
    id: 'tag73',
  },
  {
    text: 'เส้นสายดี',
    id: 'tag74',
  },
  {
    text: 'เอาตัวรอดเก่ง',
    id: 'tag75',
  },
  {
    text: 'แก้ปัญหาเก่ง',
    id: 'tag76',
  },
  {
    text: 'แข่งอะไรก็ชนะ',
    id: 'tag77',
  },
  {
    text: 'โชคดี',
    id: 'tag78',
  },
  {
    text: 'โรแมนติก',
    id: 'tag79',
  },
  {
    text: 'ใจกว้าง',
    id: 'tag80',
  },
  {
    text: 'ไหวพริบดี',
    id: 'tag81',
  },
];
