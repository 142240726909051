import {createReducer} from '@reduxjs/toolkit';
import {ListBersResult} from '../../graph/ber';
import {addListBersQueryResult, deleteBer, resetListBersQueryResult, updateBer} from './actions';

export interface MovieState {
  [page: string]: ListBersResult;
}

export const initialState: MovieState = {};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addListBersQueryResult, (state, action) => {
      const {
        result: {
          bers: {edges, pageInfo, totalCount},
        },
      } = action.payload;
      const page = state[action.payload.page] ?? {};

      state[action.payload.page] = {
        bers: {edges: [...(page.bers?.edges ?? []), ...edges], pageInfo, totalCount},
      };
    })
    .addCase(resetListBersQueryResult, (state, action) => {
      state[action.payload.page] = {} as ListBersResult;
    })
    .addCase(deleteBer, (state, action) => {
      const page = state[action.payload.page];
      if (!page) return;

      state[action.payload.page].bers.edges = state[action.payload.page].bers.edges.filter(
        (e) => e.node.id !== action.payload.id
      );
    })
    .addCase(updateBer, (state, action) => {
      const page = state[action.payload.page];
      if (!page) return;

      const {id, price, provider} = action.payload;
      const index = state[action.payload.page].bers.edges.findIndex((e) => e.node.id === id);
      if (index === -1) {
        return;
      }

      state[action.payload.page].bers.edges[index].node = Object.assign(
        state[action.payload.page].bers.edges[index].node,
        {price, provider}
      );
    })
);
